import { useState } from "react";
import { BiSolidDonateHeart } from "react-icons/bi";

const GotItModal = ({ gotitModal, gotItValue, setItModal }) => {

  const [text, setText] = useState('');

  const handleTextChange = (event) => {
    const newText = event.target.value;
    if (newText.length <= 500) {
      setText(newText);
    }
  };

  const handleSubmit = (e) => {
    e.preventdefault();
  }
  return (
    <>
      <div className={`custom-modal ${gotitModal ? "show" : ""}  `}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <div className="d-flex gap-2">
                <BiSolidDonateHeart size={25} color="#f43254" />
                <h5 className="modal-title">{gotItValue?.title}</h5>
              </div>
              <button type="button" className="btn-close" onClick={() => setItModal(false)}></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="row ">
                  <p className="col-md-7 pack-content-text mb-0">minimum</p>
                  <p className="col-md-5 pack-price-text mb-0">{gotItValue?.amount}</p>
                </div>
                <div className="row">
                  <p className="col-md-7  pack-content-text mb-0">Last Date</p>
                  <p className="col-md-5 pack-bold-text mb-0">{gotItValue?.deadline}</p>
                </div>
                <div className="row">
                  <p className="col-md-7 pack-content-text">
                    Available Packages:
                  </p>
                  <p className="col-md-5 pack-bold-text">10</p>
                </div>
                <p className="modal-dis">
                  Sed ut perspiciatis unde omnis iste natus error sit , eaque
                  ipsa quae ab illo inventore veritatis et quasi archite Sed ut
                  perspiciatis unde omnis iste natus error sit , eaque ipsa quae
                  ab illo inventore veritatis et quasi architeSed ut
                  perspiciatis unde omnis iste natus error sit , eaque ipsa quae
                  ab illo inventore veritatis et quasi archite.
                </p>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="mb-3 mt-4">
                  <div className="d-flex justify-content-between">
                    <label className="form-label"> Query</label>
                    <p class="Character-0500">
                      Character:<span class="text-style-1">{text.length}
                        {text.length < 500 ? `/${500 - text.length}` : ''}</span>
                    </p>
                  </div>
                  <textarea
                    className="form-control"
                    placeholder="Write your query here…"
                    value={text}
                    onChange={handleTextChange}
                    name="query"
                    rows="4"
                    cols="50"
                  ></textarea>
                </div>

                <div className="d-flex justify-content-end gap-3 mt-5">
                  <button className="btn text-dark" type="button" onClick={() => setItModal(false)}>Close</button>
                  <button type="submit" className="send-Query-btn">
                    Send Query
                  </button>
                  <button type="submit" className="send-Query-btn">
                    Pay Now
                  </button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GotItModal;
