import { getProfile, userLogin, userRegister } from "../../Services/Apis"


export const loginUserData = async (userDetails) => {
    try{
    return await userLogin(userDetails).then((data) => data);
   
    }
    catch(error){
        throw error
    }
    
}

export const registerUserData = async (regi_detail) => {
    const value = await userRegister(regi_detail).then((data) => data)
    return value;
}


export const UserProfile=async ()=>{
   const value = await getProfile().then((data)=>data)
  return value
}