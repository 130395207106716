import linkedinImg from "../../assets/images/linkedin-icon.png";
import twitterImg from "../../assets/images/ic-twitter.png";
import instagramimg from "../../assets/images/ic-instagram-copy.png";
import facebookimg from "../../assets/images/ic-facebook.png";
import sponcorringus from "../../assets/images/icSponsorRingUs-logo.png"
import { useTranslation } from "react-i18next";
import 'animate.css';
import WOW from 'wowjs';
import { useEffect } from "react";

const SocialFeeds = () => {
    const { t } = useTranslation();

    useEffect(() => {
      const wow = new WOW.WOW();
      wow.init();
    }, []);
  return (
    <>
      <div className="socialfeeds-div">
        <div className="container">
          <div className="socialfeeds-text-div ">
            <h2 className="socialfeeds-h2">{t("Social Feeds")}</h2>
            <p className="socialfeeds-p">{t("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua")}
            </p>
          </div>
 
          <div className="mt-5 d-lg-none ">
          <div className="socialfeeds-img-div">
             <div className="d-flex">   
              <div className="mt-5">
              <div className="">
              <div className="socialfeeds-linkedin-div ">
                <img src={linkedinImg} alt="linkedin" />
                </div>
              </div>

              <div className="">
              <div className=" socialfeeds-instagram-div">
                <img src={instagramimg} alt="instagram" />
              </div>
              </div>
              </div>
              
              <div className="">
              <div className="sf-rounded-ringus-img">
                <div className="sf-rounded-ringus-main ">
                  <div className="sf-rounded-ringus-div ">
                    <img className="sf-sponsor-img" src={sponcorringus} alt="ringus-img" />
                  </div>
                </div>
              </div>
              </div>

              <div className="mt-5">
              <div className="">
              <div className="socialfeeds-google-div">
                <img src={facebookimg} alt="facebook" />
              </div>
              </div>

             <div className="">
              <div className="socialfeeds-twitter-div">
                <img src={twitterImg} alt="x" />
              </div>
              </div>
            </div>
              </div>
          </div>
          </div>


         <div className="mt-5 d-none d-lg-block">
          <div className="socialfeeds-img-div">
             <div className="row">   
              <div className="col-4 row mt-5">
              <div className="col-md-7">
              <div className=" socialfeeds-linkedin-div wow animate__animated animate__slower animate__bounceIn animate__infinite infinite">
                <img src={linkedinImg} alt="linkedin"/>
                </div>
              </div>

              <div className="col-md-5">
              <div className="socialfeeds-instagram-div wow animate__animated animate__slower animate__bounceIn animate__infinite infinite">
                <img src={instagramimg} alt="instagram" />
              </div>
              </div>
              </div>
              
              <div className="col-4">
              <div className="sf-rounded-ringus-img">
                <div className="sf-rounded-ringus-main">
                  <div className="sf-rounded-ringus-div">
                    <img className="sf-sponsor-img" src={sponcorringus} alt="ringus-img" />
                  </div>
                </div>
              </div>
              </div>

              <div className="col-4 row mt-5">
              <div className="col-8 ">
              <div className="socialfeeds-google-div wow animate__animated animate__slower animate__bounceIn animate__infinite infinite">
                <img src={facebookimg} alt="facebook" />
              </div>
              </div>

             <div className="col-4">
              <div className="socialfeeds-twitter-div wow animate__animated animate__slower animate__bounceIn animate__infinite infinite">
                <img src={twitterImg} alt="x" />
              </div>
              </div>
            </div>
              </div>
          </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default SocialFeeds;
