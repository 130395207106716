import eventimg from "../../assets/images/event3.jpg"
import { FaLocationDot } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";
import { FaRegCalendarAlt } from "react-icons/fa";
import { HiCurrencyDollar } from "react-icons/hi";
import { Link } from "react-router-dom";


const MyEventCard=({val})=>{
    return(<>
  <div className="eventcard">
   <div className="position-relative">   
   <Link to={`/eventdetail/${val?.id}`}>
    <div className="event-info-div">Completed</div>
  <img className="myevent-card-img" src={`https://ringus.idea2reality.tech/storage/app/public/event_logo/${val?.logo}`}  alt="..."/>
  </Link>
  <div className="myevent-rectangle">
  <FaRegCalendarAlt size={18}/> 
    <p className=" date-text">{val?.start_date}</p>
  </div>
  </div>
  
  <div className="mt-2">
 
    <h5 className="feature-card-heading">{val?.event_title}</h5>
    <div className="d-flex mt-1">
    <FaLocationDot color="black" size={18}/>
    <p className="feature-card-text ">{val?.address}</p>
    </div>

    {/* <div className="d-flex mt-1">
    <HiCurrencyDollar color="black" size={18}/>
    <p className="feature-card-text">{val?.price}</p>
    </div>   */}

    <div className="d-flex mt-1">
    <FaStar color="black"  size={18}/> 
    <p className="feature-card-text">7.5 / 10</p>
    </div>  
    
   <button className="edit-event-btn mt-1">Edit Event</button>
    
  </div>
</div>

    </>)
}

export default MyEventCard;