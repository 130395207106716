import user from "../../assets/images/user.png";
import start from "../../assets/images/star2.svg";

const UserTestimonialCard=()=>{
    return(<>

    <div className="mtm-card">
       <div className="d-flex align-items-center">
        <img className="user-img" src={user} alt="usr"/>
        <div className="ms-3 mt-2">
            <h6 className="m-0 user-name-text">Rohit Sharma</h6>
            <p className="mtm-text">Organizer</p>
        </div>
       </div>
       
       <div className="mt-3"> 
        <p className="mtm-paragraph">
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo
           inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
        </p>

        <div className="d-flex gap-1">
            <img src={start} alt="str"/>
            <img src={start} alt="str"/>
            <img src={start} alt="str"/>
            <img src={start} alt="str"/>
            <img src={start} alt="str"/>
        </div>
       </div>
    </div>
    </>)
};

export default UserTestimonialCard;