import { configureStore } from '@reduxjs/toolkit'
import Login from './redux/Login'
import config_data from './redux/config'


export const store = configureStore({
  reducer: {
    login: Login,
    config_data:config_data
  },
})