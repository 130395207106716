import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';

import bannerimg1 from "../../assets/images/banner1.jpg";
import bannerimg2 from "../../assets/images/banner2.jpg";
import bannerimg3 from "../../assets/images/banner3.jpg";
import Loader from '../Loader/Loader';

// SwiperCore.use([Autoplay]);

const EventBanner=({ midBannerList,loading })=>{

  return(<>
  
  <div className='banner-div'>
   {
    loading ? <Loader/>
    :
   
  <Swiper
        spaceBetween={50}
        slidesPerView={1}
        modules={[Autoplay]}
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        className='container py-3'
      //   onSlideChange={() => console.log('slide change')}
      //   onSwiper={(swiper) => console.log(swiper)}
      >

        {midBannerList.map((item) => {
          return (
            <SwiperSlide key={item?.id}><div key={item?.id}> <img className='banner-img' src={`https://ringus.idea2reality.tech/storage/app/public/banner/${item.photo}`} alt='img1' /></div></SwiperSlide>
          )
        })}
      </Swiper>
}
    </div>  

    </>)
};

export default EventBanner;