
import { getBannerList } from "../../Services/Apis";




export const Bannerdata = async () => {
    const constant = await getBannerList().then((data) => data?.data)
    // return globalFilteredBanner = constant
    return constant
    // const filterbanner = banner_list.filter((item) => item.banner_type == banner_type)
    // return filterbanner
}


