import React from 'react';
import { Link } from 'react-router-dom';

const LaxButton = ({ buttonText }) => {
   
        return (
            <Link to="#" className="btn btn-primary lax" data-lax-preset="driftLeft">
                {buttonText}
            </Link>
        );
    }

LaxButton.defaultProps = {
    buttonText: 'Explore More About'
};
 
export default LaxButton;