import React from "react";
import { Link } from "react-router-dom";
import sendmail from "../../assets/images/sendmail.svg";
import logo from "../../assets/images/logo.svg";
import facebook from "../../assets/images/facebook.svg";
import linkedin from "../../assets/images/linkedin.svg";
import youtube from "../../assets/images/youtube.svg";
import instagram from "../../assets/images/instagram.svg";
import { useSelector } from "react-redux";

const Footer = () => {
  const allConfingData = useSelector((state) => state?.config_data?.configData)

  return (
    <footer className="footer-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="single-footer-widget">
              <h3>Support</h3>
              <span><Link to={`/${""}`} className="span-link">- Contact</Link></span>
              <span> <Link to={`/${"terms_&_conditions"}`} className="span-link">- Terms</Link></span>
              <span><Link to={`/${"privacy_policy"}`} className="span-link"> - Privacy</Link></span>
              <span><Link to={`/${"faq"}`} className="span-link">- Faq</Link></span>
            </div>
            {/* <div className="single-footer-widget mt-4">
              <h3>Email us</h3>
              <span>info@SponsorRingUs.org</span>
            </div>

            <div className="single-footer-widget mt-4">
              <h3>Telephone</h3>
              <span>+91 8889997890</span>
            </div> */}
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-footer-widget ">
              <h3 className="">Company</h3>
              <span>- About Us</span>
              <span>- Our Services</span>
              <span>- Project Details</span>
              <span>- Our Blog</span>
              <span>- Contact Us</span>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-footer-widget">
              <h3> Subscribe Newsletter</h3>
              <p>Join over 68,000 people getting our emails</p>
              <form action="#">
                <div className="input-group d-flex align-items-center mt-4">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter Your Email Address"
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                  />
                  <button className="mail-button" type="button">
                    <img src={sendmail} alt="sendmail" />
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div>
            <div className="row align-items-center mt-5">
              <div className="col-lg-5 copyright-area2"></div>
              <img className="col-2 logo" src={`${allConfingData?.base_urls?.company_logo_url}/${allConfingData?.company_logo}`} alt="logo" />
              <div className="col-lg-5 copyright-area2"></div>
            </div>

            <div className="d-flex justify-content-between  mt-4 ">
              <p>
                Copyright <i className="icofont-copyright"></i> SponsoRingUs. All
                rights reserved
              </p>
              <div>
                <ul className="social-links d-flex gap-2">
                  <p> Follow us on</p>
                  <li>
                    <img src={facebook} alt="facebook" />
                  </li>
                  <li>
                    <img src={linkedin} alt="facebook" />
                  </li>
                  <li>
                    <img src={youtube} alt="facebook" />
                  </li>
                  <li>
                    <img src={instagram} alt="facebook" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

{
  /* <ul className="social-links">
<li>
    <Link to="https://www.facebook.com/" className="facebook" target="_blank">
        <i className="icofont-facebook"></i>
    </Link>
</li>
<li>
    <Link to="https://twitter.com/" className="twitter" target="_blank">
        <i className="icofont-twitter"></i>
    </Link>
</li>
<li>
    <Link to="https://www.linkedin.com/" className="linkedin" target="_blank">
        <i className="icofont-linkedin"></i>
    </Link>
</li>
<li>
    <Link to="https://www.instagram.com/" className="instagram" target="_blank">
        <i className="icofont-instagram"></i>
    </Link>
</li>
</ul> */
}
