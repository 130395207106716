import React, { useState, useEffect } from 'react';
import AppRouter from './Routes';
import 'animate.css';
import WOW from 'wowjs';
// import Preloader from './components/Shared/Preloader';
import './assets/css/bootstrap.min.css';
import './assets/css/icofont.min.css';
import './assets/css/animate.min.css';
// import '../node_modules/react-modal-video/css/modal-video.min.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import "./assets/css/eventdetail.css";
import "./assets/css/login.css";
import "./assets/css/signup.css";
import "./assets/css/newevent.css";
import "./assets/css/yourevent.css";
import "./assets/css/home.css";
import "./assets/css/footer.css";
import "./assets/css/navbar.css";
import "./assets/css/forgotpassword.css"
import "./assets/css/privacy-terms.css"
import { useDispatch } from 'react-redux';
import { getConfigAllData } from './utils/configData/configallData';
import { configval } from './redux/config';

const App = () => {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch()


    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
    }, []);

    const demoAsyncCall = () => {
        return new Promise((resolve) => setTimeout(() => resolve(), 2000));
    };

    useEffect(() => {
        const fetchData = async () => {
            await demoAsyncCall();
            setLoading(false);
        };
        fetchData();
    }, []);

    useEffect(() => {
        getConfigAllData()
            .then((result) => {
                dispatch(configval(result?.data))
            })
    }, [])


    return (
        <>
          <AppRouter />     
        </>
    );
};

export default App;
