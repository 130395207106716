import { BiSolidDonateHeart } from "react-icons/bi";
import downarrrow from "../../assets/images/ic-downarrow.png";
import uparrow from "../../assets/images/ic-uparrow.png";
import { useState } from "react";
import { Link } from "react-router-dom";

const PackageCard=({val,getitfunc,setItModal})=>{
    const [dropdown,setDropdown]=useState(false);
       
    const get_it_now=(val)=>{
        getitfunc(val)
        setItModal(true)
    }

    return (<>
        <div className={`package-card  ${dropdown ? "open" : ""}`}>
            <button className="card-drop-btn" onClick={()=>setDropdown(!dropdown)}>
            <div className="d-flex justify-content-between pe-3 cursor-pointer">
            <div className="d-flex gap-2">
            <BiSolidDonateHeart size={25} color="#f43254"/>  
            <h5 className="pack-card-heading">{val?.title}</h5></div>
            <div>
                <img src={dropdown ? uparrow : downarrrow} alt="img"/>
            </div>
            </div>
            </button>
 
                       <hr className="w-100 mt-2 "/>
                       {/* {dropdown ? "h-0" : ""} */}
                       <div className="drop-content">
                        <div className="row ">
                           <p className="col-md-7 pack-content-text">minimum</p>
                            <p className="col-md-5 pack-price-text">{val?.amount}</p>
                        </div>
                        <div className="row">
                           <p className="col-md-7  pack-content-text">Last Date</p>
                            <p className="col-md-5 pack-bold-text" >{val?.deadline}</p>
                        </div>
                        <div className="row">
                           <p className="col-md-7 pack-content-text"> Available Packages:</p>
                            <p className="col-md-5 pack-bold-text">10</p>
                        </div>

                        {/* <p className="pack-content-text"> Sed ut perspiciatis unde omnis iste natus error sit , eaque ipsa quae ab illo inventore veritatis et quasi archite…</p> */}
                     
                       <button className="btn get-it-btn" onClick={()=>get_it_now(val)}>Get it Now</button>
                       </div>
                    </div>
                
    </>)
};
export default PackageCard;