import { FaPlus } from "react-icons/fa6";
import arrow from "../../assets/images/arrow2.svg";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import { useEffect, useState } from "react";
import { CratePackageEvent, predefinePackage } from "../../utils/packagesform/packagesFormManager";
import { getEventPeopleRangeData } from "../../utils/basicInformation/basicinformationManager";

const PackagesForm = () => {
  const [show, setShow] = useState(false)
  const [predefineData, setPredefineData] = useState([])
  const [eventPeopleRange, setEventPeopleRange] = useState([])

  const [sponsorInput, setSponsorInput] = useState({
    title: "",
    deadline: "",
    amount: "",
    people_range_id: "",
    currency: '₹',
    duration: 'weekly',
    // available_package: "",
    // comment: "",
  })
  const [errors, setErrors] = useState({});

  useEffect(() => {
    predefinePackage()
      .then((response) => {
        setPredefineData(response?.data)
      })

    getEventPeopleRangeData()
      .then((response) => {
        setEventPeopleRange(response?.data)
      })

  }, []);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSponsorInput({
      ...sponsorInput,
      [name]: value
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      CratePackageEvent(sponsorInput)
        .then((result) => {
          console.log(result, 'package')
        })
      setShow(true)
    } else {
      console.log('Form has errors');
    }
  }



  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (sponsorInput.title.trim() === '') {
      newErrors.title = 'Name is required';
      valid = false;
    }

    if (isNaN(sponsorInput.amount) || sponsorInput.amount.trim() === '') {
      newErrors.amount = 'Price is required';
      valid = false;
    }

    if (sponsorInput.people_range_id.trim() === '') {
      newErrors.people_range_id = 'Audience is required';
      valid = false;
    }


    if (sponsorInput.deadline.trim() === '') {
      newErrors.deadline = 'Date is required';
      valid = false;
    }

    // if (sponsorInput.available_package.trim() === '') {
    //   newErrors.available_package = 'Available package is required';
    //   valid = false;
    // }


    // if (sponsorInput.comment.trim() === '') {
    //   newErrors.comment = 'Comment is required';
    //   valid = false;
    // }

    setErrors(newErrors);
    return valid;
  };
  return (
    <>
      <div className="basicInfo">
        <h2 className="basicinfo-head"> What will you offer to the sponsor?</h2>
        <p className="basicinfo-p">What will you offer to the sponsor?</p>

        <div className="row mt-5">
          <div className="col-md-4">
            <div className="sidemenu">
              <button className="btn add-new-packages">
                Add New Sponsorship Package
              </button>

              <div className="select-pack-div">
                <p className="select-pack-text">
                  or select from our predefined packages:
                </p>
              </div>
              {predefineData?.map((val, index) => {
                return (
                  <>
                    <div className="d-flex align-items-center" key={val?.id}>
                      <div className="plus-div">
                        <FaPlus color="#f43254" size={18} />
                      </div>
                      <button className="package-btn" onClick={() => setSponsorInput({ name: val?.name })}>
                        <p className="package-text">{val?.name}</p>
                      </button>
                    </div>
                  </>
                )
              })}
            </div>
          </div>

          <div className="row col">
            {/* <h4 className="form-heading"> Sponsorship Package</h4> */}
            <div className="col-md-6">
              {show ?
                ""
                : <>
                  <h4 className="form-heading"> Sponsorship Package</h4>
                  <form onSubmit={onSubmit}>
                    <div className="form-row">
                      <div className="form-group">
                        <label className="form-label">Name<span className="asterisk">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          value={sponsorInput.title}
                          name="title"
                          placeholder="Name"
                          onChange={handleInputChange}
                        />
                        {errors.title && <span className="error">{errors.title}</span>}
                      </div>
                      <div className="form-group" style={{ position: "relative" }}>
                        <label>Price<span className="asterisk">*</span></label>
                        <input
                          type="number"
                          value={sponsorInput.amount}
                          name='amount'
                          className="form-control"
                          placeholder="Price"
                          onChange={handleInputChange}
                        />
                        <div className="currency_tag"><p class='currency_price'></p></div>

                        {errors.amount && <span className="error">{errors.amount}</span>}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label className="form-label">Audience<span className="asterisk">*</span></label>
                        <select className="form-control" name='people_range_id' onChange={handleInputChange}>
                          {eventPeopleRange?.map((val, index) => {
                            return (
                              <>
                                <option selected value={val?.id}>{val?.people_range}</option>
                              </>
                            )
                          })}
                        </select>
                        {errors.people_range_id && <span className="error">{errors.people_range_id}</span>}
                      </div>
                      <div className="form-group col-md-6">
                        <label className="form-label">Available Packages<span className="asterisk">*</span></label>
                        <select className="form-control" name="available_package" onChange={handleInputChange} >
                          <option selected>10</option>
                          <option>...</option>
                        </select>
                        {/* {errors.available_package && <span className="error">{errors.available_package}</span>} */}

                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label">Select Date<span className="asterisk">*</span></label>
                      <div>
                        <input type="date" className="form-control" name="deadline" value={sponsorInput.deadline} onChange={handleInputChange} />
                      </div>
                      {errors.deadline && <span className="error">{errors.deadline}</span>}
                    </div>

                    <div className="form-group">
                      <label >
                        Sponsorship Package includes:<span className="asterisk">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Write..."
                        rows="3"
                        name='comment'
                        value={sponsorInput.comment}
                        onChange={handleInputChange}
                      ></textarea>
                      {/* {errors.comment && <span className="error">{errors.comment}</span>} */}
                    </div>

                    <div className="d-flex justify-content-end align-items-center">
                      <button className="btn delete-event-btn">Close</button>
                      <div className="">
                        <button className="btn saveandcontinue-btn" type="submit">Save</button>
                      </div>
                    </div>
                  </form>
                </>
              }
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">Change currency?</label>
                <select id="inputState" className="form-control">
                  <option selected>INR</option>
                  <option>...</option>
                </select>
              </div>

              <div className="input-group donation-input">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Donation..."
                />
                <button className="edit-btn">
                  <MdOutlineEdit size={20} />
                </button>
                <button className="delete-btn ">
                  <MdDeleteOutline size={20} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end align-items-center mt-4">
          <button className="btn delete-event-btn">Delete Event</button>
          <div className="">
            <button className="btn saveandcontinue-btn">
              Save and Continue
              <img className="arrow-img" src={arrow} alt="arrow" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PackagesForm;
