import EventCard from "./EventCard";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import errow from "../../assets/images/arrow.svg";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { EventData } from "../staticdata/EventData";
import { useTranslation } from "react-i18next";

const EventNear = () => {
  const { t } = useTranslation();
  const breakpoints = {
    300: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 40,
    },
    1600: {
      slidesPerView: 5,
      spaceBetween: 40,
    },
  };
  return (
    <>
      <div className="event-near">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="event-heading mb-0">{t("Events Near by You")}</h2>
            <button className="view-all-btn">
            <div className="d-flex gap-3 align-items-center ">          
            <p className="mb-0 viewall-btn-p">{t("View All")}</p>
            <img className="" src={errow} alt="img"/>
            </div>
            </button>
          </div>
          <div className="mt-4">
            <Swiper
              className=""
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={20}
              // slidesPerView={5}
              breakpoints={breakpoints}
              navigation
              loop={true}
            >        
            <div className="even-card-div">     
              {
                EventData.map((val,index)=>{
                  return(<>
                  <SwiperSlide key={index}>
                    <EventCard key={val?.id} val={val}/>
                  </SwiperSlide>
                  </>)
                })
              }
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventNear;
