import { getEventPeopleRange, getEventSubTopic, getEventTopic, getEventType, getEventsCreate } from "../../Services/Apis"


export const getEventTypeData = async () => {
    return await getEventType().then((data) => data)
}

export const getEventTopicData = async () => {
    return await getEventTopic().then((data) => data)
}

export const getEventSubTopicData = async () => {
    return await getEventSubTopic().then((data) => data)
}

export const getEventPeopleRangeData = async () => {
    return await getEventPeopleRange().then((data) => data)
}

export const getEventsCreateInfo = async (item) => {
    return await getEventsCreate(item).then((data) => data)
}
