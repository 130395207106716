import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';
import poster1 from "../../assets/images/eventposter1.jpg";
import poster2 from "../../assets/images/eventposter2.jpg";
import poster3 from "../../assets/images/eventposter3.jpg";
import Loader from '../Loader/Loader';

const BottomBanner=({bottomBannerList,loading})=>{

  const breakpoints = {
    300: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    1200: {
      slidesPerView: 3,
      spaceBetween: 40,
    },
    1600: {
      slidesPerView: 3,
      spaceBetween: 40,
    },
  };

    return(<>
       <div className="bottom-banner d-flex justify-content-center pb-5">
        {
          loading ? <Loader/> :
        
       <Swiper
      spaceBetween={50}
      breakpoints={breakpoints}
      modules={[Autoplay]}
      loop={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      className='container py-3'
    //   onSlideChange={() => console.log('slide change')}
    //   onSwiper={(swiper) => console.log(swiper)}
    >
      {bottomBannerList?.map((item) => {
          return (
            <SwiperSlide key={item?.id}>
              <img className="eventposter-img" src={`https://ringus.idea2reality.tech/storage/app/public/banner/${item?.photo}`} alt="img1"/>
              </SwiperSlide>
          )
        })}
      </Swiper>
     }
        </div>
    </>)
}

export default BottomBanner;