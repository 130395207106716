import arrow from "../../assets/images/page-navi-arrow.svg";
import cardimg1 from "../../assets/images/musicevent1.jpg";
import { FaRegCalendarAlt, FaStar } from "react-icons/fa";
import { FaHandLizard, FaLocationDot } from "react-icons/fa6";
import sponsorringus from "../../assets/images/SponsorRingUs-Event.png";
import { IoCloseSharp } from "react-icons/io5";
import squareimg1 from "..//../assets/images/square-event-mage1.jpeg";
import squareimg2 from "..//../assets/images/square-event-image2.webp";
import squareimg3 from "..//../assets/images/square-event-image3.jpeg";
import squareimg4 from "..//../assets/images/square-event-image4.jpeg";
import vertiimg1 from "..//../assets/images/verti-event-image.jpeg";
import vertiimg2 from "..//../assets/images/verti-event-image2.jpeg";

import PackageCard from "../../components/EventDetail/PackageCard";
import linkedinImg from "../../assets/images/event-linkedin-img.png";
import eventshapeImg from "../../assets/images/event-x-img.svg";
import eventgoogleimg from "../../assets/images/event-google-icon.svg";
import eventfacebookimg from "../../assets/images/event-facebook-img.png";
import { use } from "i18next";
import { useEffect, useState } from "react";
import { Event_detail } from "../../utils/eventdetail/eventdetail";
import { useParams } from "react-router-dom";
import YouTube from "react-youtube";
import Loader from "../../components/Loader/Loader";
import pdfimg from "../../assets/images/pdf.png";
import docimg from "../../assets/images/doc.png";
import pdf2img from "../../assets/images/pdf-copy-2.png";
import user from "../../assets/images/user.png";
import start from "../../assets/images/star2.svg";

import { Document, Page } from "react-pdf";
import DocViewer from "@cyntler/react-doc-viewer";
import GotItModal from "../../components/EventDetail/GotItModal";
import ReactImageGallery from "react-image-gallery";

const EventDetail = () => {
  const params = useParams();
  const id = params?.id;
  const [event, setEvent] = useState({});
  const [pdfFile, setPdfFile] = useState("");
  const [loading, setLoading] = useState(true);
  const [gotItValue,setGotItValue]=useState({});
  const [gotitModal,setItModal]=useState(false);

  const docs = [
    {
      uri: `https://ringus.idea2reality.tech/storage/app/public/event_docs/${pdfFile}`,
    }, // Remote file
  ];

  const evetdetail = async () => {
    try {
      const response = await Event_detail(id);
      setEvent(response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    evetdetail();
  }, []);

  const opts = {
    height: "350",
    width: "640",
    playerVars: {
      autoplay: 1,
      mute: 1,
    },
  };

  const getitfunc = (val) => {
    setGotItValue(val)
  }


  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div
            className="detail-banner"
            style={{
              backgroundImage: `url('https://ringus.idea2reality.tech/storage/app/public/event_banner/${event?.banner}')`,
            }}
          >
            <div className="container">
              <div className="pt-4 d-flex justify-content-between align-items-center">
                <div className="d-flex gap-2 align-items-center">
                  <p className="navigate-p mb-0">My Event</p>
                  <img src={arrow} alt="arw" />
                  <p className="navigate-p text-light">Event Details</p>
                </div>

                <div className="attendees-btn">
                  <p className="Attendees-text">
                    Attendees:<span className="text-style-1"> 1-100</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="detail-img-div">
              <img
                className="cardimg1"
                src={`https://ringus.idea2reality.tech/storage/app/public/event_logo/${event?.logo}`}
                alt="img"
              />
            </div>
          </div>
          <div className="container pb-5">
            <div className="row">
              <div className="col-md-8">
                <div className="d-flex justify-content-between detailevent-card">
                  <div className="detail-text-div">
                    <h2 className="event-pro-head">{event?.event_title}</h2>
                    <div className="d-flex align-items-center gap-2">
                      <FaRegCalendarAlt size={18} />
                      <div className="d-flex gap-2">
                        <p className="detail-p-text mb-0">
                          {event?.start_date}
                        </p>
                        <p className="detail-p-text mb-0">To</p>
                        <p className="detail-p-text mb-0">{event?.end_date}</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <FaLocationDot color="black" size={18} />
                      <p className="detail-p-text ">{event?.address}</p>
                    </div>

                    <div className="d-flex gap-2">
                      <FaStar color="#f43254" size={18} />
                      <p className="detail-p-text">7.5 / 10</p>
                    </div>
                  </div>
                </div>

                <div>
                  
                  <p className="t-paragraph" dangerouslySetInnerHTML={{ __html: event?.about_host }}></p>
                </div>

                <div className="social-media-div">
                  <div className="d-flex align-items-center gap-2">
                    <h2 className="social-m-head">Social Media</h2>
                    <hr className="w-75 " />
                  </div>
                  <div className="social-back-img d-flex justify-content-between mt-4">
                    <div className="linkedin-div wow animate__animated animate__slower animate__bounceIn animate__infinite infinite">
                      <img src={linkedinImg} alt="linkedin" />
                    </div>

                    <div className="google-div wow animate__animated animate__slower animate__bounceIn animate__infinite infinite">
                      <img src={eventgoogleimg} alt="google" />
                    </div>

                    <div className="rounded-ringus-img">
                      <div className="rounded-ringus-main">
                        <div className="rounded-ringus-div">
                          <img src={sponsorringus} alt="ringus-img" />
                        </div>
                      </div>
                    </div>

                    <div className="google-div wow animate__animated animate__slower animate__bounceIn animate__infinite infinite">
                      <img src={eventfacebookimg} alt="facebook" />
                    </div>

                    <div className="linkedin-div wow animate__animated animate__slower animate__bounceIn animate__infinite infinite">
                      <img src={eventshapeImg} alt="x" />
                    </div>
                  </div>
                </div>

                <div className="video-div">
                  <div className="d-flex align-items-center gap-2">
                    <h2 className="social-m-head">Video</h2>
                    <hr className="w-75" />
                  </div>
                  <div className="d-flex justify-content-center gap-4 mt-3">
                    <div>
                      {/* <video
                    className="event-video"
                    width="100%"
                    controls
                    autoPlay
                    loop
                    muted
                  >
                    <source
                      src={event?.video_link}
                      type="video/mp4"
                    />
                    Sorry, your browser doesn't support embedded videos.
                  </video> */}
                      <YouTube videoId={event?.video_link} opts={opts} />
                    </div>
                  </div>
                </div>

                <div className="social-media-div">
                  <div className="d-flex align-items-center gap-2">
                    <h2 className="social-m-head">What is the event about?</h2>
                    <hr className="w-50" />
                  </div>

                  <div>
                    <p className="t-paragraph1" dangerouslySetInnerHTML={{ __html: event?.about_event }}></p>
                  </div>
                </div>

                <div className="social-media-div">
                  <div className="d-flex align-items-center gap-2">
                    <h2 className="social-m-head">
                      Why should a sponsor support your event?
                    </h2>
                    <hr className="w-25" />
                  </div>

                  <div>
                    <p className="t-paragraph1" dangerouslySetInnerHTML={{ __html: event?.why_to_support_event }}>

                    </p>
                  </div>
                </div>

                <div className="social-media-div">
                  <div className="d-flex align-items-center gap-2">
                    <h2 className="social-m-head">Images</h2>
                    <hr className="w-75" />
                  </div>

                  <div className="row mt-3">
                    {event?.event_gellary
                      ?.filter((val) => {
                        return val?.mime_type == "jpg" || val?.mime_type == "png";
                      })

                      ?.map((val) => {
                        return (
                          <>
                            <div className="col-md-3 mt-4" key={val?.id}>
                              <button className="img-select-button">
                              <img
                                className="event-gellary-img"
                                src={`https://ringus.idea2reality.tech/storage/app/public/event_image/${val?.filename}`}
                                alt="img1"
                              />
                              </button>
                            </div>
                            
                          </>
                        );
                      })
                    }
                  </div>

                  {/* <div className="row">
                <div className="col-md-3">
                  <div>
                    <img
                      className="bottom-square-img"
                      src={squareimg1}
                      alt="img1"
                    />
                  </div>
                  <div>
                    <img
                      className="bottom-square-img mt-4"
                      src={squareimg2}
                      alt="img2"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <img
                      className="bottom-verti-img"
                      src={vertiimg1}
                      alt="img1"
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div>
                    <img
                      className="bottom-square-img"
                      src={squareimg3}
                      alt="img1"
                    />
                  </div>
                  <div>
                    <img
                      className="bottom-square-img mt-4"
                      src={squareimg4}
                      alt="img2"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <img
                      className="bottom-verti-img"
                      src={vertiimg2}
                      alt="img1"
                    />
                  </div>
                </div>
                </div> */}
                </div>

                <div className="social-media-div">
                  <div className="d-flex align-items-center gap-2">
                    <h2 className="social-m-head">Attached Documents</h2>
                    <hr className="w-50" />
                  </div>
                  <div className="row mt-3 hi">
                    {event?.event_gellary
                      ?.filter((val) => {
                        return val?.mime_type == "pdf";
                      })
                      ?.map((val) => {
                        return (
                          <>
                            <div
                              className="col-md-3 mt-2 pdf-div"
                              key={val?.id}
                            >
                              <button
                                className="btn p-0"
                                onClick={() => setPdfFile(val?.filename)}
                              >
                                <img src={pdfimg} alt="pdf" />
                              </button>
                              <p className="pdf-title">{val?.filename}</p>
                            </div>

                          </>
                        );
                      })}
                  </div>
                  {pdfFile == "" ? (
                    ""
                  ) : (
                    <>
                      <div className="mt-4">
                        <div className="d-flex justify-content-end">
                          <button
                            className="btn p-0 border-none text-dark"
                            onClick={() => setPdfFile("")}
                          >
                            <IoCloseSharp size={24} />
                          </button>
                        </div>
                        <DocViewer documents={docs} />
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="packages-div">
                  <h3>Packages</h3>
                  {event?.events_packages?.map((val, index) => (
                    <>
                      <PackageCard key={val?.id} val={val} ind={index} getitfunc={getitfunc} setItModal={setItModal} />
                    </>
                  ))}
                </div>
              </div>
            </div>

            {/* Modal */}
            <GotItModal gotitModal={gotitModal} gotItValue={gotItValue} setItModal={setItModal} />
          </div>

          <div className="container reviews-div mt-5 mb-5">
            <div className="d-flex align-items-center gap-2">
              <h2 className="social-m-head">Reviews</h2>
              <hr className="w-75" />
            </div>

            <div className="row gap-3">
              {[...Array(3)].map((_, index) => (
                <>
                  <div className="col-md-4 reviews-card">
                    <div className="d-flex align-items-center">
                      <img className="reviews-img" src={user} alt="usr" />
                      <div className="ms-3 mt-2">
                        <h6 className="m-0 reviews-name-text">Rohit Sharma</h6>
                        <p className="reviews-text">Organizer</p>
                      </div>
                    </div>

                    <div className="mt-3">
                      <p className="reviews-paragraph">
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo
                        inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                      </p>

                      <div className="d-flex gap-1">
                        <img src={start} alt="str" />
                        <img src={start} alt="str" />
                        <img src={start} alt="str" />
                        <img src={start} alt="str" />
                        <img src={start} alt="str" />
                      </div>
                    </div>
                  </div>
                </>
              ))}


            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EventDetail;
