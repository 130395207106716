import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { useParams } from "react-router"
import FAQ from "../../components/faq/Faq";


const Privacypolicy = () => {
    const pathname = useParams();
    const counter = useSelector(state => state.config_data.configData)
    const [faqData, setFaqData] = useState([])
    const [pagesData, setPagesData] = useState('')


    useEffect(() => {
        if (pathname.path == 'privacy_policy') {
            setPagesData(counter.privacy_policy, 'p')
        } else if (pathname.path == "terms_&_conditions") {
            setPagesData(counter['terms_&_conditions'], 'ff');
        } else if (pathname.path == "faq") {
            setFaqData(counter.faq)
        } else {
        }
    }, [pathname])


    return (
        <section className="container">
            <div className="policy-main pt-3 mt-5 mb-5 pb-3 ">
                {
                    pathname.path == "faq" ? <div className="mt-5 mb-5 p-5">
                        <FAQ faqData={faqData} />
                    </div> :
                        <div>
                            <div className="text-center" style={{ fontFamily: 'poppines' }}>
                                <h2 dangerouslySetInnerHTML={{ __html: pagesData }}></h2>
                            </div>
                            <div className="p-5">

                            </div>
                        </div>
                }

            </div>
        </section>
    )
}

export default Privacypolicy