import { useState } from "react";
import preview from "../../assets/images/preview.svg";
import BasicInformation from "../../components/NewEvents/BasicInfoForm";
import PackagesForm from "../../components/NewEvents/PackagesForm";
import PitchForm from "../../components/NewEvents/PitchForm";
import OrganizerForm from "../../components/NewEvents/OrganizerForm";
import { Link } from "react-router-dom";


const NewEvent = () => {
  const [tabValue, setTabValue] = useState("Basic-Information")

  return (<>
    <div className="newevent container fluid">
      <div className="row">
        <div className="col-6">
          <h6>New Event</h6>
          <p>Enter the following details</p>
        </div>
        <div className="col-6">
          <div className="d-flex justify-content-end gap-4">
            <Link to="/preview" className="btn d-flex gap-2 preview-btn"> <img src={preview} alt="pre" />Preview </Link>
            <button className="btn submitbutton">Submit for Validation</button>
          </div>
        </div>
      </div>

      <div className="">
        <div className="row main-tab">
          <button onClick={() => setTabValue("Basic-Information")} className={`${tabValue == "Basic-Information" ? "active-tab-button" : "tab-button"} col-3 `}>Basic Information</button>
          <button onClick={() => setTabValue("packages")} className={`${tabValue == "packages" ? "active-tab-button" : "tab-button"} col-3`}>PACKAGES</button>
          <button onClick={() => setTabValue("pitch")} className={`${tabValue == "pitch" ? "active-tab-button" : "tab-button"} col-3`}>Pitch</button>
          <button onClick={() => setTabValue("organizer")} className={`${tabValue == "organizer" ? "active-tab-button" : "tab-button"} col-3`}>Organizer</button>
        </div>

        {
          tabValue == "Basic-Information" ?
            <div className="mt-5">
              <BasicInformation />
            </div>
            :
            tabValue == "packages" ?
              <div className="mt-5">
                <PackagesForm />
              </div>
              :
              tabValue == "pitch" ?
                <div className="mt-5">
                  <PitchForm /> </div>
                :
                tabValue == "organizer" ?
                  <div className="mt-5">
                    <OrganizerForm /> </div> :

                  ""
        }
      </div>
    </div>
  </>)
};

export default NewEvent;