import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import facebook from "../../assets/images/facebook-icon.png";
import linkedin from "../../assets/images/linkedin-icon.png";
import google from "../../assets/images/google-icon.png";
import logo from "../../assets/images/SponsorRingUs-icon.svg";
import instagram from "../../assets/images/icons8-instagram-48.png";
import { registerUserData } from "../../utils/user/UserManager";
import { ToastContainer } from "react-toastify";

const SignUp = () => {
  const [signup, setSignup] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    confirm_password: "",
  });
  const [selectedOption, setSelectedOption] = useState('emailid');
  const [errors, setErrors] = useState({});
  const navigate = useNavigate()


  const onSubmit = (e) => {
    e.preventDefault();
    const Data = {
      f_name: signup.firstName,
      l_name: signup.lastName,
      email: signup.email,
      password: signup.password,
      phone: signup.phone,
    };

    const newErrors = {};
    if (!signup.firstName || !signup.firstName.trim() === "") {
      newErrors.firstName = "First name is required";
    }

    if (!signup.lastName || !signup.lastName.trim() === "") {
      newErrors.lastName = "Last name is required";
    }

    if (!signup.email || !signup.email.trim() === "") {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(signup.email)) {
      newErrors.email = "Email is invalid";
    } else {
      newErrors.email = "";
    }

    if (!signup.phone || !signup.phone.trim() === "") {
      newErrors.phone = "Mobile is required";
    } else if (signup.phone.trim().length < 10) {
      newErrors.phone = "Mobile should be at least 10 characters long";
    } else {
      newErrors.phone = "";
    }

    if (!signup.password || !signup.password.trim() === "") {
      newErrors.password = "Password is required";
    } else if (signup.password.trim().length < 8) {
      newErrors.password = "Password should be at least 8 characters long";
    } else {
      newErrors.password = "";
    }

    if (!signup.confirm_password || !signup.confirm_password.trim() === "") {
      newErrors.confirm_password = "Confirm password is required";
    } else if (signup.confirm_password !== signup.password) {
      newErrors.confirm_password = "Passwords do not match";
    } else {
      newErrors.confirm_password = "";
    }
    setErrors(newErrors);

    if (
      newErrors.email ||
      newErrors.phone ||
      newErrors.firstName ||
      newErrors.lastName ||
      newErrors.password ||
      newErrors.confirm_password
    ) {
      return;
    } else {
      registerUserData(Data).then((response) => {
        // toast.success('🦄 Wow so easy!', {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        //   });
        console.log("res", response);
        navigate("/")
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSignup({
      ...signup,
      [name]: value,
    });
  };

  return (
    <>
      <ToastContainer />
      <section className="signup-area">
        <div className="login-signup-header">
          <Link to="/">
            <div className=" w-100">
              <img src={logo} alt="logo" />
            </div>
          </Link>
        </div>

        <div className="d-table1">
          <div className="container-fluid">
            <div className="signup-form mt-2">
              <div className="row d-flex align-items-center">
                <div className="d-flex justify-content-between">
                  <h3>Sign Up</h3>
                  <p className="Dont-have-an-accoun-signup  ">
                    Already have a SponsorRingUs account?{" "}
                    <Link to={"/login"}>
                      <span className="text-style-1">Sign In</span>
                    </Link>
                  </p>
                </div>

                <div className="d-flex mt-4">
                  <div className="d-flex flex-column align-items-center col-3">
                    <p className="login-p">Sign Up with Social Account</p>
                    <div className="d-flex flex-column gap-4 mt-4">
                      <button className="login-with-facebook">
                        <img
                          className="login-btn-icon"
                          src={facebook}
                          alt="linkedin"
                        />
                        Continue with Facebook
                      </button>

                      <button className="login-with-linkedin">
                        <img
                          className="login-btn-icon"
                          src={linkedin}
                          alt="linkedin"
                        />
                        Continue with Linkedin
                      </button>
                      <button className="login-with-google">
                        <img
                          className="login-btn-icon"
                          src={google}
                          alt="linkedin"
                        />
                        Continue with Google
                      </button>

                      <button className="login-with-instagram">
                        <img
                          className="login-btn-icon"
                          src={instagram}
                          alt="instagram"
                        />
                        Continue with Instagram
                      </button>
                    </div>
                  </div>

                  <div className="col-1  justify-content-center">
                    <div className="row justify-content-center">
                      <div className="center-portion d-flex justify-content-center">
                        {/* <div className="center-abs"> */}
                        <div className="center-abs center-line-1 "></div>
                        <div className="center-abs or-div">
                          <span className="or-text">OR</span>
                        </div>
                        {/* </div> */}
                      </div>
                    </div>
                    {/* <div className="center-line ms-3"></div>
                  <div className="or-div"> <span className="or-text">OR</span></div>
                <div className="center-line ms-3"></div> */}
                  </div>

                  <div className="col-8">
                    <div className="row pad-x-35">
                      <div className="col">
                        <div className="mx-4">
                          <p className="login-p">Sign Up with email</p>
                        </div>
                      </div>
                    </div>
                    <form onSubmit={onSubmit}>
                      <div className="row">
                        <div className="col-12 row">
                          <div className="col-6 form-group ">
                            <label className="login-label">First name</label>
                            <input
                              type="text"
                              className="form-control rounded-3"
                              placeholder="Enter first name"
                              name="firstName"
                              value={signup.firstName}
                              onChange={handleInputChange}
                            />
                            {errors.firstName && (
                              <span
                                className=""
                                style={{ color: "red", fontSize: "13px" }}
                              >
                                {errors.firstName}
                              </span>
                            )}
                          </div>
                          <div className="col-6 form-group ">
                            <label className="login-label">Last name</label>
                            <input
                              type="text"
                              className="form-control rounded-3"
                              placeholder="Enter last name"
                              name="lastName"
                              value={signup.lastName}
                              onChange={handleInputChange}
                            />
                            {errors.lastName && (
                              <span
                                className=""
                                style={{ color: "red", fontSize: "13px" }}
                              >
                                {errors.lastName}
                              </span>
                            )}
                          </div>
                        </div>

                        {/* <div>
                          <div className="">
                            <label>Select an option:</label>
                            <div className="d-flex gap-3">
                            <div className="form-check">
                              <input
                                type="radio"
                                name="otpoption"
                                value="emailid"
                                checked={selectedOption === 'emailid'}
                                className="form-check-input"
                                onChange={(e)=>setSelectedOption(e?.target?.value)}
                              />
                              <label
                                htmlFor="option1"
                                className="form-check-label"
                              >
                                Email Id
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                type="radio"
                                name="otpoption"
                                value="phone"
                                checked={selectedOption === 'phone'}
                                className="form-check-input"
                                onChange={(e)=>setSelectedOption(e?.target?.value)}
                              />
                              <label
                                htmlFor="option2"
                                className="form-check-label">
                                Phone
                              </label>
                            </div>
                            </div>
                          </div>
                        </div> */}

                        <div className="col-12 row">

                          <div className="col-6 form-group ">
                            <label className="login-label">Email id</label>
                            <input
                              type="email"
                              className="form-control rounded-3"
                              placeholder="Enter email id"
                              name="email"
                              value={signup.email}
                              onChange={handleInputChange}
                            />
                            {errors.email && (
                              <span
                                className=""
                                style={{ color: "red", fontSize: "13px" }}
                              >
                                {errors.email}
                              </span>
                            )}
                          </div>

                          <div className="col-6 form-group ">
                            <label className="login-label">Mobile</label>
                            <input
                              type="number"
                              className="form-control rounded-3"
                              placeholder="Enter Mobile"
                              name="phone"
                              value={signup.phone}
                              onChange={handleInputChange}
                            />
                            {errors.phone && (
                              <span
                                className=""
                                style={{ color: "red", fontSize: "13px" }}
                              >
                                {errors.phone}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-12 row">
                          <div className="col-6 form-group ">
                            <label className="login-label">Password</label>
                            <input
                              type="password"
                              className="form-control rounded-3"
                              placeholder="Enter Password"
                              name="password"
                              value={signup.password}
                              onChange={handleInputChange}
                            />
                            {errors.password && (
                              <span
                                className=""
                                style={{ color: "red", fontSize: "13px" }}
                              >
                                {errors.password}
                              </span>
                            )}
                          </div>
                          <div className="col-6 form-group ">
                            <label className="login-label">
                              Confirm password
                            </label>
                            <input
                              type="password"
                              className="form-control rounded-3"
                              placeholder="Enter Confirm password"
                              name="confirm_password"
                              value={signup.confirm_password}
                              onChange={handleInputChange}
                            />
                            {errors.confirm_password && (
                              <span
                                className=""
                                style={{ color: "red", fontSize: "13px" }}
                              >
                                {errors.confirm_password}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="form-group ">
                            <button
                              type="submit"
                              className="btn login-btn col-12 mt-2"
                            >
                              Sign Up
                            </button>
                          </div>
                        </div>
                        <p className="By-signing-up-you-ag">
                          By signing up you agree to the
                          <span className="text-style-1"> <Link to={`/${"terms_&_conditions"}`} className="span-link"> Terms of Use </Link> </span>
                          and
                          <span className="text-style-1"> <Link to={`/${"privacy_policy"}`} className="span-link"> Privacy Policy </Link> </span>
                        </p>
                      </div>
                      {/* <Link to="#" className="forgot-text">
                      Forgot your password?
                   </Link> */}
                      {/* <div className="col-12">
                    <button type="submit" className="login-btn mt-4">
                       Sign Up
                    </button>
                  </div> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUp;
