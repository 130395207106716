import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    configData: {},
}


export const config_data = createSlice({
    name: 'configValues',
    initialState: initialState,
    reducers: {
        configval: (state, action) => {
            state.configData = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { configval } = config_data.actions;

export default config_data.reducer;