import { useTranslation } from "react-i18next";
import UserTestimonialCard from "./UserTestimonialcard";
import errow from "../../assets/images/arrow.svg";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";



const UserTestimonials=()=>{
    const { t } = useTranslation();

    const breakpoints = {
        300: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        1600: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
      };

    return(<>
     <div className="member-testimonial container">
        <div className="container"> 
        <div className="d-flex justify-content-between"> 
            <h2 className="User-test-heading">{t("User Testimonials")}</h2>  
            <button className="view-all-btn"> 
            <div className="d-flex gap-3 align-items-center ">                
            <p className="viewall-btn-p mb-0">{t("View All")}</p>
            <img className="" src={errow} alt="img"/>
            </div>
            </button> 
            </div>
        <div className="d-flex justify-content-center mt-4">
        <Swiper
              className=""
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={20}
              breakpoints={breakpoints}
              navigation
              loop={true}
            >

         <div className="">
         {[...Array(3)].map((_, index) => (
            <>
            <div className="">
            <SwiperSlide key={index}>
            <UserTestimonialCard key={index}/>
            </SwiperSlide>
            </div>
            </>
           ))}
          </div>
            </Swiper>
        </div>
        </div>
      </div>
    </>)
};

export default UserTestimonials;