import eventimg from "../../assets/images/event3.jpg"
import { FaLocationDot } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";
import { FaRegCalendarAlt } from "react-icons/fa";
import { HiCurrencyDollar } from "react-icons/hi";
import { Link } from "react-router-dom";


const FeaturedCard=({val})=>{
    return(<>
    <Link to={`/eventdetail/${val?.id}`}>
    <div className="eventcard">
   <div className="position-relative">   
  <img className="card-img-top" src={val?.image}  alt="..."/>
  
  <div className="featured-rectangle">
  <FaRegCalendarAlt size={18}/> 
    <p className=" date-text">25 Dec 2023</p>
  </div>
  </div>
  <div className="mt-2">
    <h5 className="feature-card-heading">{val?.title}</h5>

    <div className="d-flex mt-1">
    <FaLocationDot color="black" size={18}/>
    <p className="feature-card-text ">{val?.location}</p>
    </div>

    <div className="d-flex mt-1">
    <HiCurrencyDollar color="black" size={18}/>
    <p className="feature-card-text">{val.price}</p>
    </div>  

    <div className="d-flex mt-1">
    <FaStar color="black"  size={18}/> 
    <p className="feature-card-text">7.5 / 10</p>
    </div>  

  </div>
</div>
</Link>
    </>)
}

export default FeaturedCard;