import WhySponsorRingUsCard from "./WhySponsorRinguscard";
import arrow from "../../assets/images/arrow2.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Token } from "../../utils/Token";
import { useEffect, useState } from "react";


const WhySponsorRingUsComp = () => {
  const { t } = useTranslation();
  const [token,setToken] =useState("")


  const gettoken =()=>{
    const tkn= Token();
    setToken(tkn)
  }

  useEffect(()=>{
    gettoken()
  },[])

  return (
    <>
      <div className="SponsorRingUs">
        <div className="container-fluid pb-3">
          <h2 className="Why-SponsorRingUs">
            {t("Why")}<span className="text-style-1"> {t("Sponsor")}</span>RingUs?
          </h2>
          <div className="d-flex justify-content-center">
            <p className="Lorem-ipsum-dolor-si">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>

          <div className="cards">
              <WhySponsorRingUsCard/>
          </div>
         
         {
          token ? null :
          <div className="d-flex justify-content-center">
          <Link className="ringus-startbutton btn" to="/login">
            <div >Get Started Now</div>
            <img className="arrow-img ps-2" src={arrow} alt="arrow"/>
          </Link>
        </div>
         }
       
        </div>
      </div>
    </>
  );
};

export default WhySponsorRingUsComp;
