
import axios from 'axios';

const Axiosapi = axios.create({
  baseURL: 'https://ringus.idea2reality.tech/api/v1',
});

Axiosapi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('LoginToken');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default Axiosapi;