import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
// import HomeDefault from './components/pages/HomeDefault';
import Navigation from './components/Navigation/Navigation';
// import HomeTwo from './components/pages/HomeTwo';
// import HomeThree from './components/pages/HomeThree';
// import HomeFive from './components/pages/HomeFive';
// import AboutOne from './components/pages/AboutOne';
// import AboutTwo from './components/pages/AboutTwo';
// import AboutThree from './components/pages/AboutThree';
// import PricingOne from './components/pages/PricingOne';
// import PricingTwo from './components/pages/PricingTwo';
// import SpeakersOne from './components/pages/SpeakersOne';
// import SpeakersTwo from './components/pages/SpeakersTwo';
// import SpeakersThree from './components/pages/SpeakersThree';
// import ScheduleOne from './components/pages/ScheduleOne';
// import ScheduleTwo from './components/pages/ScheduleTwo';
// import ScheduleThree from './components/pages/ScheduleThree';
// import ScheduleFour from './components/pages/ScheduleFour';
import Login from './pages/LoginPage/Login';
import Signup from './pages/SignUpPage/Signup';
import ComingSoon from './pages/ComingSoonPage/ComingSoon';
import YourEvents from './pages/YourEventsPage/YourEvents';
import NewEvent from './pages/NewEventPage/NewEvent';
import { useEffect } from 'react';
// import NotFound from './components/pages/NotFound';
// import Faq from './components/pages/Faq';
// import Contact from './components/pages/Contact';
// import BlogOne from './components/pages/BlogOne';
// import BlogTwo from './components/pages/BlogTwo';
// import BlogThree from './components/pages/BlogThree';
// import BlogFour from './components/pages/BlogFour';
// import BlogDetails from './components/pages/BlogDetails';
// import Sponsor from './components/pages/Sponsor';
import { useState } from 'react';
import EventDetail from './pages/EventDetail/EventDetail';
import Home from './pages/HomePage/Home';
import ForgotPassword from './pages/ForgotPasswordPage/ForgotPassword';
import Footer from './components/Common/Footer';
import Privacypolicy from './pages/FooterPages/Privacypolicy';
import Preview from './pages/Preview/Preview';

// Conditionally render Navigation
// const renderNavigation = () => {
//     if (
//         !(
//             window.location.pathname === '/login' ||
//             window.location.pathname === '/signup' ||
//             window.location.pathname === '/coming-soon' ||
//             window.location.pathname === '/error-404'
//         )
//     ) {
//         return <Navigation />;
//     }
// };


const AppRouter = () => {
    const location = useLocation();
    const [showNavbar, setShowNavbar] = useState(true);

    useEffect(() => {
        setShowNavbar(!['/login', '/signup', "/forgotpassword"].includes(location.pathname));
    }, [location.pathname]);

    const renderNavigation = () => {
        if (!(window.location.pathname === '/login' || window.location.pathname === '/signup' || window.location.pathname === '/coming-soon' || window.location.pathname === '/error-404')) {
            return <Navigation />;
        }
    }
   
    return (
        <>
            {showNavbar && <Navigation />}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                {/* <Route path="/coming-soon" element={<ComingSoon />} /> */}
                <Route path='/yourevents' element={<YourEvents />} />
                <Route path='/newevent' element={<NewEvent />} />
                <Route path='/eventdetail/:id' element={<EventDetail />} />
                <Route path='/forgotpassword' element={<ForgotPassword />} />
                <Route path='/:path' element={<Privacypolicy />} />
                <Route path='/preview' element={<Preview/>} />
                

                {/* <Route path="/" element={<HomeDefault />} />
                <Route path="/home-two" element={<HomeTwo />} />
                <Route path="/home-three" element={<HomeThree />} />
                <Route path="/home-four" element={<HomeFour />} />
                <Route path="/home-five" element={<HomeFive />} />
                <Route path="/about-1" element={<AboutOne />} />
                <Route path="/about-2" element={<AboutTwo />} />
                <Route path="/about-3" element={<AboutThree />} />
                <Route path="/pricing-1" element={<PricingOne />} />
                <Route path="/pricing-2" element={<PricingTwo />} />
                <Route path="/speakers-1" element={<SpeakersOne />} />
                <Route path="/speakers-2" element={<SpeakersTwo />} />
                <Route path="/speakers-3" element={<SpeakersThree />} />
                <Route path="/schedule-1" element={<ScheduleOne />} />
                <Route path="/schedule-2" element={<ScheduleTwo />} />
                <Route path="/schedule-3" element={<ScheduleThree />} />
                <Route path="/schedule-4" element={<ScheduleFour />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />}/>
                <Route path="/coming-soon" element={<ComingSoon />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/blog-1" element={<BlogOne />} />
                <Route path="/blog-2" element={<BlogTwo />} />
                <Route path="/blog-3" element={<BlogThree />} />
                <Route path="/blog-4" element={<BlogFour />} />
                <Route path="/single-blog" element={<BlogDetails />} />
                <Route path="/sponsors" element={<Sponsor />} />
                <Route path="/error-404" element={<NotFound />} />
                <Route path="*" element={<NotFound />} /> */}
            </Routes>

            {showNavbar && <Footer />}


        </>
    );
};

export default AppRouter;