import { createPackageEvent, getPredefinePackage } from "../../Services/Apis"


export const predefinePackage = async () => {
    return await getPredefinePackage().then((data) => data)
} 


export const CratePackageEvent = async (info) => {
    return await createPackageEvent(info).then((data) => data)
} 