import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import ModalVideo from "react-modal-video";
import star from "../../assets/images/star.svg";
import cale from "../../assets/images/calendar.svg";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import 'swiper/css/autoplay';
import { Autoplay, Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

// Import Swiper styles
import 'swiper/css';
import Loader from "../Loader/Loader";

const options = {
  loop: true,
  nav: true,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  items: 1,
  navText: [
    "<i className='icofont-swoosh-left'><</i>",
    "<i className='icofont-swoosh-right'>></i>",
  ],
};

const MainBanner = ({ mainBannerList,loading }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };


  return (
    <>
      {/* <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId="cRXm1p-CNyk"
        onClose={() => setIsOpen(false)}
      /> */}
{
    loading ? <div className="mainbanner-loader-div"><Loader/></div>  :
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
        navigation
        loop={true}
       autoplay={{
         delay: 3000,
         disableOnInteraction: false,
       }}
      // onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper) => console.log(swiper)}
      >
  {mainBannerList.map((item, index) => {
  return (<>
            <SwiperSlide>
              <div key={index} className="main-banner" style={{ backgroundImage: `url('https://ringus.idea2reality.tech/storage/app/public/banner/${item?.photo}')` }}>
                <div className="d-table">
                  <div className="d-table-cell">
                    <div className="container">
                      <VisibilitySensor>
                        {({ isVisible }) => (
                          <div
                            className={`main-banner-content ${index === 1 ? "banner-content-center" : ""
                              }`}>
                            <h1 className={`${isVisible ? "animated fadeInUp" : ""} `}>
                              The event solution for sponsorship activation
                            </h1>

                            <h2 className="SponsoRingUs-brings text-light  mt-2 py-4">
                              <span className="text-#f43254 ">SponsoRingUs</span> brings
                              event organizers and sponsors together.
                            </h2>
                            <div className="d-flex justify-content-center gap-3 gap-md-5 mt-xl-4 ">

                              <button className={`event-sponsor-btn d-none d-lg-block ${isVisible ? "animated fadeInDown" : ""}`}>
                                <img className="star-icon" src={star} alt="img" /> Sponsor - Event sponsor</button>

                              <button className={`event-sponsor-btn d-lg-none ${isVisible ? "animated fadeInDown" : ""}`}>
                                <img className="star-icon" src={star} alt="img" /> Sponsor</button>
                              {/* <button
                              className={`sponsor-btn text-center ${isVisible ? "animated fadeInDown" : ""
                                }`}>
                              <img className="star-icon" src={star} alt="img" />
                              Sponsor Event sponsor
                            </button> */}

                              <button className={`event-organisor-btn d-none d-lg-block ${isVisible ? "animated fadeInDown" : ""}`}><img className="star-icon" src={cale} alt="img" /> Host - Event Organisor</button>

                              <button className={`event-organisor-btn d-lg-none ${isVisible ? "animated fadeInDown" : ""}`}><img className="star-icon" src={cale} alt="img" /> Host</button>

                              {/* <button
                              className={`host-btn  text-dark text-center  ${isVisible ? "animated fadeInDown" : ""} `}>
                              <img className="star-icon" src={cale} alt="img" />
                              Host – Event Organisor
                            </button> */}
                            </div>
                          </div>
                        )}
                      </VisibilitySensor>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </>)
        })}
      </Swiper>
        }

    </>
  );
};

export default MainBanner;
