import upload from "../../assets/images/upload-icon.png";
import arrow from "../../assets/images/arrow2.svg";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import { useEffect, useState } from "react";
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import { getEventPeopleRangeData, getEventSubTopicData, getEventTopicData, getEventTypeData, getEventsCreateInfo } from "../../utils/basicInformation/basicinformationManager";
import PackagesForm from "./PackagesForm";

const BasicInformation = () => {
  const [type, setType] = useState([]);
  const [eventTopic, setEventTopic] = useState([]);
  const [subEventTopic, setSubEventTopic] = useState([]);
  const [eventPeopleRange, setEventPeopleRange] = useState([]);

  const [center, setCenter] = useState({ lat: 24.5980386, lng: 73.6866158 });
  const [marker, setMarker] = useState(null);

  const [input, setInput] = useState({
    banner: '',
    logo: '',
    title: '',
    type: '',
    categories_id: '',
    sub_topic_id: '',
    address: '',
    country: '',
    state: '',
    city: '',
    pin_code: '',
    contact_number: '',
    start_date: '',
    start_time: '',
    end_date: '',
    end_time: '',
    people_range: '',
    link: '',
    facebook: '',
    twitter: '',
    linkedin: '',
    google: '',
    latitude: 37.7749,
    longitude: -122.4194,
    discription: "qwertyuioop",


  })
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getEventTypeData()
      .then((response) => {
        setType(response?.data)
      })

    getEventTopicData()
      .then((response) => {
        setEventTopic(response?.data)
      })

    getEventSubTopicData()
      .then((response) => {
        setSubEventTopic(response?.data)
      })

    getEventPeopleRangeData()
      .then((response) => {
        setEventPeopleRange(response?.data)
      })
  }, []);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'banner' || name === 'logo') {
      setInput({
        ...input,
        [name]: files[0]
      });
    } else {
      setInput({
        ...input,
        [name]: value,
      });
    }
  };


  const onSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      getEventsCreateInfo(input)
        .then((results) => {
          console.log(results)
        })
    } else {
      console.log('Form has errors');
    }
  }

  const handleMapClick = (event) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setCenter({ lat, lng });
    setMarker({ lat, lng });
    getAddressFromLatLng(lat, lng);

  };
  const getAddressFromLatLng = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyA3BYK_Siw8ebziSskitaA5YMjbvcO6n4U`
      );

      if (response.ok) {
        const data = await response.json();
        if (data.results && data.results.length > 0) {
          const full_address = data.results[0].address_components;

          const location = full_address.find(loc => loc.types.includes('postal_code'));
          const location1 = full_address.find(loc => loc.types.includes('locality'));
          setInput({ pin_code: location.long_name, address: location1.long_name })

        } else {
          console.log('Address not found');
        }
      } else {
        console.log('Failed to fetch address');
      }
    } catch (error) {
      console.error('Error fetching address:', error);
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    // if (input.banner.trim() === '') {
    //   newErrors.banner = 'File is required';
    //   valid = false;
    // }

    // if (input.logo.trim() === '') {
    //   newErrors.logo = 'File is required';
    //   valid = false;
    // }

    if (input.title.trim() === '') {
      newErrors.title = 'Title is required';
      valid = false;
    }

    if (input.type.trim() === '') {
      newErrors.type = 'Type is required';
      valid = false;
    }

    if (input.categories_id.trim() === '') {
      newErrors.categories_id = 'Topic is required';
      valid = false;
    }

    if (input.sub_topic_id.trim() === '') {
      newErrors.sub_topic_id = 'Sub topic is required';
      valid = false;
    }

    if (input.address.trim() === '') {
      newErrors.address = 'Address is required';
      valid = false;
    }

    if (input.country.trim() === '') {
      newErrors.country = 'Country is required';
      valid = false;
    }

    if (input.state.trim() === '') {
      newErrors.state = 'State is required';
      valid = false;
    }

    if (input.city.trim() === '') {
      newErrors.city = 'City is required';
      valid = false;
    }

    if (input.pin_code.trim() === '') {
      newErrors.pin_code = 'Pin code is required';
      valid = false;
    }

    if (input.contact_number.trim() === '') {
      newErrors.contact_number = 'Number is required';
      valid = false;
    } else if (input.contact_number.trim().length < 10) {
      newErrors.contact_number = 'Password should be at least 10 characters long';
      valid = false;
    }

    if (input.start_date.trim() === '') {
      newErrors.start_date = 'Date is required';
      valid = false;
    }

    if (input.start_time.trim() === '') {
      newErrors.start_time = 'Time is required';
      valid = false;
    }

    if (input.end_date.trim() === '') {
      newErrors.end_date = 'Date is required';
      valid = false;
    }

    if (input.end_time.trim() === '') {
      newErrors.end_time = 'Time is required';
      valid = false;
    }

    if (input.people_range.trim() === '') {
      newErrors.people_range = 'Range is required';
      valid = false;
    }

    if (input.facebook.trim() === '') {
      newErrors.facebook = 'Facebook url is required';
      valid = false;
    }

    if (input.twitter.trim() === '') {
      newErrors.twitter = 'Twitter url is required';
      valid = false;
    }

    if (input.linkedin.trim() === '') {
      newErrors.linkedin = 'Linkedin url is required';
      valid = false;
    }

    if (input.google.trim() === '') {
      newErrors.google = 'Google url is required';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  }

  return (
    <>
      <div className="basicInfo">
        <h2 className="basicinfo-head">The Basics</h2>
        <p className="basicinfo-p">
          Add a logo, title and other important information.
        </p>

        <div className="row">
          <div className="col-6">
            <p className="Bannerimg-head">Banner Image<span className="asterisk">*</span></p>
            <label className="bannerimg-div">
              <input
                className="d-none"
                type="file"

                name="banner"
                multiple
                onChange={handleInputChange}
              />
              <div className="upload-image-content">
                <img src={upload} alt="upload" />
                <p className="uploadimg-font">Upload Banner Image</p>
                <p className="img-format-text">Image format: .jpg or .png</p>
                <p className="img-format-text">
                  Recommended size: w: 1440px, H: 600px
                </p>
              </div>
            </label>
            {/* {errors.banner && <span className="error">{errors.banner}</span>} */}
          </div>
          <div className="col-6">
            <p className="Bannerimg-head">Logo<span className="asterisk">*</span></p>
            <label className="bannerimg-div ">
              <input
                className="d-none"
                type="file"

                name="logo"
                multiple
                onChange={handleInputChange}
              />
              <div className="upload-image-content">
                <img src={upload} alt="upload" />
                <p className="uploadimg-font">Upload Logo Image</p>
                <p className="img-format-text"> Image format: .jpg or .png</p>
                <p className="img-format-text">
                  Recommended size: w: 274px, H: 330px
                </p>
              </div>
            </label>
            {/* {errors.logo && <span className="error">{errors.logo}</span>} */}
          </div>

          <div className="mt-4">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="form-group col-md-6">
                  <label className="form-label">title<span className="asterisk">*</span></label>
                  <input
                    type="text"
                    value={input.title}
                    name="title"
                    className="form-control"
                    placeholder="james"
                    onChange={handleInputChange}
                  />
                  {errors.title && <span className="error">{errors.title}</span>}
                </div>
                <div className="form-group col-md-6">
                  <label className="form-label">Type<span className="asterisk">*</span></label>
                  <select className="form-control" name="type" onChange={handleInputChange}>
                    {type?.map((val, index) => {
                      return (
                        <>
                          <option key={val?.id} value={val?.id}>{val?.name}</option>
                        </>
                      )
                    })
                    }
                  </select>
                  {errors.type && <span className="error">{errors.type}</span>}
                </div>
              </div>

              <div className="row">
                <div className="form-group col-3">
                  <label className="form-label">Topic<span className="asterisk">*</span></label>
                  <select className="form-control" name="categories_id" onChange={handleInputChange}>
                    {eventTopic?.map((val, index) => {
                      return (
                        <>
                          <option key={val?.id} value={val?.id}>{val?.name}</option>
                        </>
                      )
                    })}
                  </select>
                  {errors.categories_id && <span className="error">{errors.categories_id}</span>}
                </div>
                <div className="align-items-center col-3">
                  <label className="form-label">Sub Topic<span className="asterisk">*</span></label>
                  <select className="form-control" name="sub_topic_id" onChange={handleInputChange}>
                    {subEventTopic?.map((val, index) => {
                      return (
                        <>
                          <option key={val?.id} value={val?.id}>{val?.name}</option>
                        </>
                      )
                    })}
                  </select>
                  {errors.sub_topic_id && <span className="error">{errors.sub_topic_id}</span>}
                </div>
              </div>

              <div className="row">
                <h5 className="my-3 form-section-heading">Address</h5>
                <LoadScript
                  googleMapsApiKey="AIzaSyA3BYK_Siw8ebziSskitaA5YMjbvcO6n4U"
                >
                  <GoogleMap
                    mapContainerStyle={{ height: '400px', width: '100%' }}
                    center={center}
                    zoom={15}
                    onClick={handleMapClick}
                  >
                    {marker && <Marker position={marker} />}
                  </GoogleMap>
                </LoadScript>



                <div className="form-group col-md-3 mt-2">
                  <label className="form-label">Country<span className="asterisk">*</span></label>
                  <select className="form-control" name="country" onChange={handleInputChange}>
                    <option selected>Select Country...</option>
                    <option>india</option>
                  </select>
                  {errors.country && <span className="error">{errors.country}</span>}
                </div>

                <div className="form-group col-md-3 mt-2">
                  <label className="form-label">State<span className="asterisk">*</span></label>
                  <select className="form-control" name="state" onChange={handleInputChange}>
                    <option selected>Select State...</option>
                    <option>rajasthan</option>
                  </select>
                  {errors.state && <span className="error">{errors.state}</span>}
                </div>

                <div className="form-group col-md-3 mt-2">
                  <label className="form-label">City<span className="asterisk">*</span></label>
                  <select className="form-control" name="city" onChange={handleInputChange}>
                    <option selected>Select City...</option>
                    <option>udaipur</option>
                  </select>
                  {errors.city && <span className="error">{errors.city}</span>}
                </div>

                <div className="form-group col-md-3 mt-2">
                  <label className="form-label">Zip Code<span className="asterisk">*</span></label>
                  <input
                    type="number"
                    value={input.pin_code}
                    name="pin_code"
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="zip code..."
                  />
                  {errors.pin_code && <span className="error">{errors.pin_code}</span>}
                </div>

                <div className="form-group col-6">
                  <label className="form-label">Address<span className="asterisk">*</span></label>
                  <input
                    type="text"
                    value={input.address}
                    name="address"
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="address..."
                  />
                  {errors.address && <span className="error">{errors.address}</span>}
                </div>

                <div className="form-group col-md-3">
                  <label className="form-label">Contact Number<span className="asterisk">*</span></label>
                  <input
                    type="number"
                    value={input.mobile}
                    name="contact_number"
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="mobile.."
                  />
                  {errors.contact_number && <span className="error">{errors.contact_number}</span>}
                </div>
              </div>

              <div className="row">
                <h5 className="my-3 form-section-heading">
                  When is the event happening?
                </h5>
                <div className="form-group col-md-3">
                  <label className="form-label">Start Date<span className="asterisk">*</span></label>
                  <div>
                    <input type="date" className="form-control" name="start_date" value={input.start_date} onChange={handleInputChange} />
                  </div>
                  {errors.start_date && <span className="error">{errors.start_date}</span>}
                </div>

                <div className="form-group col-md-3">
                  <label className="form-label">Start Time<span className="asterisk">*</span></label>
                  <div>
                    <input type="time" className="form-control" step="1" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" name="start_time" value={input.start_time} onChange={handleInputChange} />
                  </div>
                  {errors.start_time && <span className="error">{errors.start_time}</span>}
                </div>

                <div className="form-group col-md-3">
                  <label className="form-label">End Date<span className="asterisk">*</span></label>

                  <div>
                    <input type="date" className="form-control" name="end_date" value={input.end_date} onChange={handleInputChange} />
                  </div>
                  {errors.end_date && <span className="error">{errors.end_date}</span>}
                </div>

                <div className="col-md-3">
                  <label className="form-label">End Time<span className="asterisk">*</span></label>
                  <div className=" ">
                    <input type="time" className="form-control" step="1" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" name="end_time" value={input.end_time} onChange={handleInputChange} />
                  </div>
                  {errors.start_time && <span className="error">{errors.start_time}</span>}
                </div>
              </div>

              <div className="form-group col-md-6">
                <h5 className="my-3 form-section-heading">
                  How many people are attending the event?
                </h5>
                <label className="form-label">People<span className="asterisk">*</span></label>
                <select className="form-control" name="people_range" onChange={handleInputChange}>
                  {eventPeopleRange?.map((val, index) => {
                    return (
                      <>
                        <option key={val?.id} value={val?.id}>{val?.people_range}</option>
                      </>
                    )
                  })}
                </select>
                {errors.people_range && <span className="error">{errors.people_range}</span>}
              </div>

              <div className="row">
                <h5 className="my-3 form-section-heading">Links</h5>
                <div className="form-group col-md-6">
                  <label className="form-label">Link URL<span className="asterisk">*</span></label>
                  <input type="url"
                    value={input.link}
                    name='link'
                    onChange={handleInputChange}
                    className="form-control" />
                </div>

                <div className="col-2 d-flex align-items-center">
                  <button className="btn addlink-btn ">Add Link</button>
                </div>
              </div>

              <div className="row">
                <h5 className="my-3 form-section-heading">Social Media</h5>
                <div className="form-group col-md-6">
                  <label className="form-label">Facebook URL<span className="asterisk">*</span></label>
                  <input
                    type="url"
                    value={input.facebook}
                    name="facebook"
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="url"
                  />
                  {errors.facebook && <span className="error">{errors.facebook}</span>}
                </div>
                <div className="form-group col-md-6">
                  <label className="form-label">Twitter URL<span className="asterisk">*</span></label>
                  <input
                    type="url"
                    value={input.twitter}
                    name="twitter"
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="url"
                  />
                  {errors.twitter && <span className="error">{errors.twitter}</span>}

                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6">
                  <label className="form-label">Linkedin URL<span className="asterisk">*</span></label>
                  <input
                    type="url"
                    value={input.linkedin}
                    name="linkedin"
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="url"
                  />
                  {errors.linkedin && <span className="error">{errors.linkedin}</span>}
                </div>
                <div className="form-group col-md-6">
                  <label className="form-label">Google<span className="asterisk">*</span></label>
                  <input
                    type="url"
                    value={input.google}
                    name="google"
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="url"
                  />
                  {errors.google && <span className="error">{errors.google}</span>}
                </div>
              </div>
              <div className="d-flex justify-content-end align-items-center">
                <button className="btn delete-event-btn">Delete Event</button>
                <div className="">
                  <button className="btn saveandcontinue-btn" type="submit">
                    {" "}
                    Save and Continue{" "}
                    <img className="arrow-img" src={arrow} alt="arrow" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default BasicInformation;
