import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    message:"",
}


export const Login = createSlice({
  name: 'login',
  initialState:initialState,
  reducers: {
    loginmessage: (state,action) => {
       state.message = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { loginmessage } = Login.actions;

export default Login.reducer;