import { FaLocationDot, FaStar } from "react-icons/fa6";
import errow from "../../assets/images/arrow.svg";
import multiphoto from "../../assets/images/ic-multi-photo.png";
import pastimg1 from "../../assets/images/pastevent-img1.jpg";
import pastimg2 from "../../assets/images/pastevent-img2.jpg";
import pastimg3 from "../../assets/images/pastevent-img3.jpg";
import pastimg4 from "../../assets/images/pastevent-img4.jpg";
import pastimg5 from "../../assets/images/pastevent-img5.jpg";

const PastEvents = () => {
  return (
    <>
     
        <div className="container pt-4 d-none d-lg-block">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="event-heading mb-0">Past Events</h2>
            <button className="view-all-btn">
              <div className="d-flex gap-3 align-items-center ">
                <p className="viewall-btn-p mb-0">View All</p>
                <img className="" src={errow} alt="img" />
              </div>
            </button>
          </div>

          <div className="row mt-4">
          {[...Array(2)].map((_, index) => (
            <>
            <div key={index} className="position-relative col-md-6 ">
              <div>
                <img
                  className="pastevent-center-img"
                  src={pastimg5}
                  alt="pastimg4"
                />
              </div>

              <button className="showall-photo-btn">
                <img src={multiphoto} alt="showall" /> Show all Photos
              </button>

              <div className="d-flex">
                <div className="">
                  <img
                    className="pastevent-img1"
                    src={pastimg2}
                    alt="pastimg1"
                  />
                </div>
                <div className="">
                  <img
                    className="pastevent-img2"
                    src={pastimg1}
                    alt="pastimg2"
                  />
                </div>
              </div>
              <div className="d-flex">
                <div className="">
                  <img
                    className="pastevent-img3"
                    src={pastimg3}
                    alt="pastimg3"
                  />
                </div>
                <div className="">
                  <img
                    className="pastevent-img4"
                    src={pastimg4}
                    alt="pastimg4"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between mt-2 pe-5">
                <h3 className="pastevent-card-title">
                  let's Lunch Party Together
                </h3>
                <div className="pastevent-hr-line"></div>
                <div className="d-flex mt-1">
                  <FaLocationDot color="black" size={18} />
                  <p className="feature-card-text ">New delhi,delhi</p>
                </div>
                <div className="pastevent-hr-line"></div>
                <div className="d-flex mt-1">
                  <FaStar color="#f43254" size={18} />
                  <p className="feature-card-text">7.5 / 10</p>
                </div>
              </div>
            </div>
            </>    
          ))}
        </div>
      </div>
    </>
  );
};

export default PastEvents;
