import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import imageicon from "../../assets/images/image-upload-icon.svg";
import Documenticon from "../../assets/images/document-upload-icon.svg";
import arrow from "../../assets/images/arrow2.svg";
import YouTube from 'react-youtube';
import { createPitchData } from '../../utils/pitchform/pitchInfoForm';

const PitchForm = () => {
  const [video_link, setVideo_link] = useState('')
  const [images, setImages] = useState([])
  const [documents, setDocuments] = useState([])
  const [about_event, setAbout_event] = useState('');
  const [about_host, setAbout_host] = useState('');
  const [why_to_support_event, setWhy_to_support_event] = useState('')


  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ 'size': [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image', 'video'],
      ['clean']
    ],
  };

  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ];

  const handleEditorChange = (html) => {
    setAbout_event(html);
  };

  const handleEditorChange1 = (html) => {
    setAbout_host(html);
  };

  const handleEditorChange2 = (html) => {
    setWhy_to_support_event(html);
  };

  const handleChangeURL = (e) => {
    setVideo_link(e.target.value)
  }

  const handleFileChangeImage = (event) => {
    const files = event.target.files;
    const imageArray = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      // if (file.type.startsWith('image/')) {
      //   imageArray.push(URL.createObjectURL(file));
      // }
      imageArray.push(file)
    }
    setImages([...images, ...imageArray]);
  }

  const handleFileChangeDocument = (event) => {
    const files = event.target.files;
    const documentArray = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      documentArray.push(file);
    }

    setDocuments([...documents, ...documentArray]);
  };



  const submitData = () => {
    const Data = {
      video_link: video_link,
      images: images,
      documents: documents,
      about_event: about_event,
      about_host: about_host,
      why_to_support_event: why_to_support_event
    }

    createPitchData(Data)
      .then((result) => {
        console.log(result)
      })
  }

  return (<>
    <div className="basicInfo">
      <h2 className="basicinfo-head"> What will you offer to the sponsor?</h2>
      <p className="basicinfo-p">What will you offer to the sponsor?</p>

      <div>
        <h4 className="form-heading">Videos</h4>
        <div className="row">
          <div className="col-md-8">
            <label className="form-label">Video URL</label>
            <div className="d-flex form-group mt-3">
              <div className="col-8">
                <input
                  type="url"
                  onChange={handleChangeURL}
                  className="form-control"
                />
              </div>
              <div>
                <button className="btn Addlink-btn ms-4">Add Link</button>
              </div>
            </div>
          </div>
          {/* <YouTube videoId="wZeHdXlJx4M?si=SxSV9l17ABlJvX1d" opts={opts} /> */}

        </div>
      </div>

      <div className='reactQuill-div2'>
        <h4 className="form-heading">What is the event about?</h4>
        <div className="row reactQuill">
          <div className="col-md-12">
            <div>
              <ReactQuill
                className='reactQuill'
                theme="snow"
                value={about_event}
                onChange={handleEditorChange}
                modules={modules}
                formats={formats}
                placeholder="Write something..."

              />
            </div>
          </div>
        </div>
      </div>

      <div className='reactQuill-div2'>
        <h4 className="form-heading">What is the about host?</h4>
        <div className="row reactQuill">
          <div className="col-md-12">
            <div>
              <ReactQuill
                className='reactQuill'
                theme="snow"
                value={about_host}
                onChange={handleEditorChange1}
                modules={modules}
                formats={formats}
                placeholder="Write something..."

              />
            </div>
          </div>
        </div>
      </div>

      <div className='reactQuill-div2'>
        <div className="row">
          <h4 className="form-heading">Why should a sponsor support your event?</h4>
          <div className="col-md-12">
            <div>
              <ReactQuill
                className='reactQuill'
                theme="snow"
                value={why_to_support_event}
                onChange={handleEditorChange2}
                modules={modules}
                formats={formats}
                placeholder="Write something..."
              />
            </div>
          </div>
        </div>
      </div>

      <div className=''>
        <div className="row">
          <h4 className="form-heading">Images (Maximum 6)</h4>
          <div className="d-flex flex-column align-items-center col-md-12">
            <label>
              <img className='upload-icon' src={imageicon} alt='img-upload' />
              <input className='d-none' type='file' multiple onChange={handleFileChangeImage} />
            </label>
            <div className=''>The maximunm size of an image can be 1 MB</div>
            <div className='pt-3'>
              <button className="btn Addlink-btn ms-4">Add Images</button>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-3">
            {images.map((file, index) => (
              <div key={index} className="px-2">
                <img
                  src={URL.createObjectURL(file)}
                  alt={`selected-${index}`}
                  style={{ maxWidth: '200px', maxHeight: '200px' }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className=''>
        <div className="row mt-4">
          <h4 className="form-heading">Documents (Maximum 3)</h4>
          <div className="d-flex flex-column align-items-center col-md-12">
            <label className=''>
              <img className='upload-icon' src={Documenticon} alt='img-upload' />
              <input className='d-none' type='file' multiple onChange={handleFileChangeDocument} />
            </label>
            <div className=''>The maximunm size of a file can be 4 MB</div>
            <div className='pt-3'>
              <button className="btn Addlink-btn ms-4">Add Documents</button>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-3 mb-3">
            {documents.map((file, index) => (
              <div key={index} className="px-2">

                <p>{file.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end align-items-center mt-4">
        <button className="btn delete-event-btn">Delete Event</button>
        <div className="">
          <button className="btn saveandcontinue-btn" onClick={submitData}>
            Save and Continue
            <img className="arrow-img" src={arrow} alt="arrow" />
          </button>
        </div>
      </div>
    </div>
  </>)
};

export default PitchForm;

