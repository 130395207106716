import emptyevent from "../../assets/images/empty-events.svg";
import { FaRegCalendarAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { EventData } from "../../components/staticdata/EventData";
import FeaturedCard from "../../components/Home/Featuredcard";
import FilterIcon from "../../assets/images/filter-icon.svg";
import sorticon from "../../assets/images/sort-icon.svg";
import { useEffect, useState } from "react";
import { get_my_event } from "../../utils/myevent/MyEvent";
import MyEventCard from "../../components/myevent/MyEventcard";
import { Circles } from "react-loader-spinner";
import Loader from "../../components/Loader/Loader";

const YourEvents = () => {
  const [myEvent, getMyEvent] = useState([]);
  const [filterDrop, setFilterDrop] = useState(false);
  const [sortDrop, setSortDrop] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [eventTab,setEventTab]=useState("all_event")

  const getEvent = async () => {
    try {
      const response = await get_my_event();
      const data = response?.data;
      getMyEvent(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEvent();
  }, []);

  
  return (
    <>
      {Loading ? (
        <Loader/>
      ) : (
        <div className="your-events container">
          <div className="d-flex justify-content-between">
            <h2 className="your-events-heading">Your Events</h2>

            { myEvent?.length <= 0 ?  "" :
            <div className="d-flex gap-2 align-items-center">
              <div className="position-relative">
                <button
                  type="button"
                  className="btn filter-btn"
                  onClick={() => {setFilterDrop(!filterDrop);setSortDrop(false) }}
                >
                  <img src={FilterIcon} alt="filter" /> Filters
                </button>

                {filterDrop ? (
                  <div className="">
                    <ul
                      className="d-flex flex-column dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <li>
                        <button className="avatar-dropdown-item">All</button>
                      </li>
                      <li>
                        <button className="avatar-dropdown-item">
                          Music Event
                        </button>
                      </li>
                      <li>
                        <button className="avatar-dropdown-item">
                          Sponsored Event
                        </button>
                      </li>
                    </ul>
                  </div>
                ) : null}
              </div>
              <div>
                <button
                  type="button"
                  className="btn filter-btn"
                  onClick={() => {setSortDrop(!sortDrop);setFilterDrop(false) }}
                >
                  <img src={sorticon} alt="sort" /> Sort
                </button>

                <ul
                  className={`${
                    sortDrop ? "d-flex" : ""
                  } flex-column dropdown-menu`}
                >
                  <li>
                    <a className="dropdown-item" href="#">
                      Action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Another action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Something else here
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <Link to={"/newevent"} className="btn Create-new-Event">
                  <FaRegCalendarAlt size={18} /> Create New Event
                </Link>
              </div>
            </div>
            }

          </div>
          {myEvent?.length <= 0 ? (
            <div className="your-event-content">
              <div className="yourevent-content-div">
                <img
                  className="emptyevent-image"
                  src={emptyevent}
                  alt="empty"
                />
                <p className="Looks-like-you-haven">
                  Looks like you haven't added any events yet! Don't worry, it's
                  easy to get started. Click on the
                  <span className="text-style-1">‘Create New Event'</span>button
                  to begin creating memorable moments.
                </p>
                <Link to={"/newevent"} className="btn Create-new-Event">
                  <FaRegCalendarAlt size={18} /> Create New Event
                </Link>
              </div>
            </div>
          ) : (
            <>

            {/* Tab Button*/}
          <div className="mt-3">
            <div className="d-flex justify-content-between py-3">
                
                <div className="d-flex flex-column align-items-center">
                <button className={eventTab == "all_event" ? "select-myevent-tab" :"myevent-tab"} onClick={()=>setEventTab("all_event")}>All Event</button>
                  { eventTab == "all_event" ? <div className="tab-bottom-line"></div> :"" }
                </div>
                
                <div className="d-flex flex-column align-items-center">
                <button className={eventTab == "draft_event" ? "select-myevent-tab" :"myevent-tab"} onClick={()=>setEventTab("draft_event")}>Draft Event</button>
                { eventTab == "draft_event" ? <div className="tab-bottom-line"></div> :"" }
                </div>
                
                <div className="d-flex flex-column align-items-center">
                <button className={eventTab == "in_review_event" ? "select-myevent-tab" :"myevent-tab"} onClick={()=>setEventTab("in_review_event")}>In Review Event</button>
                 { eventTab == "in_review_event" ? <div className="tab-bottom-line"></div> :"" }
                </div>
      
                <div className="d-flex flex-column align-items-center">
                <button className={eventTab == "published_event" ? "select-myevent-tab" :"myevent-tab"} onClick={()=>setEventTab("published_event")}>Published Event</button>
                 { eventTab == "published_event" ? <div className="tab-bottom-line"></div> :"" }
                </div>
                
                <div className="d-flex flex-column align-items-center">
                <button className={eventTab == "rejected_event" ? "select-myevent-tab" :"myevent-tab"} onClick={()=>setEventTab("rejected_event")}>Rejected Event</button>
                 { eventTab == "rejected_event" ? <div className="tab-bottom-line"></div> :"" }
                </div>
                
                <div className="d-flex flex-column align-items-center">
                <button className={eventTab == "past_event" ? "select-myevent-tab" :"myevent-tab"} onClick={()=>setEventTab("past_event")}>Past Event</button>
                 { eventTab == "past_event" ? <div className="tab-bottom-line"></div> :"" }
                </div>
            </div>
         </div>
            
            <div className="event-card-div">
              <div className="row ">
                {myEvent?.map((val, index) => {
                  return (
                    <>
                      <div className="col-md-3 mt-4" key={index}>
                        <MyEventCard key={index} val={val}/>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default YourEvents;
