import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { createOrganizerPitchData } from '../../utils/organizerpitchform/organizerpitch';


const OrganizerForm = () => {
    const [about_yourself, setAbout_yourself] = useState('');
    const [error, setError] = useState('')

    const modules = {
        toolbar: [

            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ 'size': [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            ['clean']
        ],
    };

    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
    ];

    const handleEditorChange = (html) => {
        setAbout_yourself(html);
    };

    const handleSubmitData = () => {
        if (!about_yourself.trim()) {
            setError('About yourself is required.');
            return
        } else {
            setError('')
        }

        const Data = {
            about_yourself: about_yourself
        }
        createOrganizerPitchData(Data)
            .then((result) => {
                console.log(result)
            })
    }



    return (<>
        <div className="basicInfo">
            <h2 className="basicinfo-head">About yourself!</h2>
            <p className="basicinfo-p">Tell the sponsor who you are and why he should trust you.</p>

            <div className='reactQuill-div'>
                <div className="row">
                    <h4 className="form-heading">Why should a sponsor support your event?</h4>
                    <div className="col-md-12">
                        <div>
                            <ReactQuill
                                className='reactQuill'
                                theme="snow"
                                value={about_yourself}
                                onChange={handleEditorChange}
                                modules={modules}
                                formats={formats}
                                placeholder="Write something..."
                            />
                        </div>
                    </div>
                </div>
            </div>

            {error && <span className="error">{error}</span>}

            <div className="d-flex justify-content-end align-items-center organize-bottom-btn">
                <button className="btn delete-event-btn">Delete Event</button>
                <div className="">
                    <button className="btn saveandcontinue-btn" onClick={handleSubmitData}>
                        Submit for Validation
                    </button>
                </div>
            </div>
        </div>
    </>)
};

export default OrganizerForm;