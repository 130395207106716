import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';

const Partner = ({ partnerPostarList }) => {
  const breakpoints = {
    300: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    1200: {
      slidesPerView: 3,
      spaceBetween: 40,
    },
    1600: {
      slidesPerView: 3,
      spaceBetween: 40,
    },
  };

  return (
    <section className="partner-area ptb-60">
      <div className="container">
        <div className="section-title d-lg-flex justify-content-between">
          <div>
            <span>Check Who Makes This Event Possible!</span>
            <h2 className='our-event-heading'>Our Event <b>Sponsors</b></h2>

            {/* <div className="bar"></div> */}
          </div>
          <div>
            <button className="become-sponsor ">Become a Sponsor</button>
          </div>
        </div>

        <div className="row">

          <div className="parter-banner d-flex justify-content-center">
            <Swiper
              spaceBetween={50}
              breakpoints={breakpoints}
              modules={[Autoplay]}
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              className='container d-flex justify-content-center'
            //   onSlideChange={() => console.log('slide change')}
            //   onSwiper={(swiper) => console.log(swiper)}
            >
              {partnerPostarList?.map((item) => {
                return (
                  <SwiperSlide className='parter_slide' key={item?.id}>
                    <div className='' key={item?.id}>
                      <img className="partnerposter-img" src={`https://ringus.idea2reality.tech/storage/app/public/profile/${item?.user?.image}`} alt="img1" />
                      <p className="text-center">{`${item?.user?.f_name} ${item?.user?.l_name}`}</p>
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>

          </div>
        </div>
      </div>
    </section>
  );
};

export default Partner;
