import musicshow1 from "../../assets/images/musicevent1.jpg";
import musicshow2 from "../../assets/images/musicevent2.jpg";
import musicshow3 from "../../assets/images/musicevent3.jpg";
import musicshow4 from "../../assets/images/musicevent4.jpg";
import magicshow from "../../assets/images/magic-show1.jpg";
import circusshow from "../../assets/images/circus-show2.jpg";
import circusshow2 from "../../assets/images/circus-show3.jpg";
import magicshow2 from "../../assets/images/magic-show4.jpg";

export const EventData=[{
    id:1,
    title:"Music Show",
    location:"New Delhi, Delhi",
    price:"499 onwards",
    image:musicshow1,
    
},
{
    id:2,
    title:"Night Music Show",
    location:"Goa",
    price:"1000 onwards",
    image:musicshow2,
    
},
{
    id:3,
    title:"Club Show",
    location:"New Delhi, Delhi",
    price:"899 onwards",
    image:musicshow3,
    
},
{
    id:4,
    title:"Bar Club Show",
    location:"New Delhi, Delhi",
    price:"1299 onwards",
    image:musicshow4,
    
},
// ===============================================
{
    id:5,
    title:"Magic Show",
    location:"New Delhi, Delhi",
    price:"499 onwards",
    image:magicshow2,
    
},
{
    id:6,
    title:"Circus Show",
    location:"Goa",
    price:"1000 onwards",
    image:circusshow,
    
},
{
    id:7,
    title:"Club Show",
    location:"Night Circus Show",
    price:"899 onwards",
    image:circusshow2,
    
},
{
    id:8,
    title:"Night Magic Show",
    location:"New Delhi, Delhi",
    price:"1299 onwards",
    image:magicshow2,
    
},
]