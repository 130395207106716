import axios from "axios";
import { BANNER_URL, BASE_URL, CREATE_PACKAGES_EVENT, EVENT_DETAIL, GET_CONFIG_DATA, GET_CURRENCY, GET_EVENTS_CREATE, GET_EVENT_PEOPLE_RANGE, GET_EVENT_SUB_TOPIC, GET_EVENT_TOPIC, GET_EVENT_TYPE, GET_MY_EVENT, GET_PREDEFINE_PACKAGE, GET_PROFILE, GET_SPONSOR, ORGANIZER_PITCH_FORM, PITCH_FORM, USER_LOGIN_URL, USER_REGISTER_URL } from "./RequestUrl";
import Axiosapi from "./AxiosInterceptor";


export const getBannerList = async () => {
  try {
    const response = await Axiosapi.get(`${BANNER_URL}`);
    return response;
  } catch (error) {
    console.log("error", error);
  }
};


// Login Register
export const userLogin = async (user) => {
  try {
    const response = await axios.post(`${BASE_URL}${USER_LOGIN_URL}`, user, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error
  }
};

export const userRegister = async (user) => {
  try {
    const response = await axios.post(`${BASE_URL}${USER_REGISTER_URL}`, user, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    console.log(error)
  }
}

// Basic Information
export const getEventType = async () => {
  try {
    const response = await Axiosapi.get(`${GET_EVENT_TYPE}`);
    return response;
  } catch (error) {
    console.log("error", error);
  }
}

export const getEventTopic = async () => {
  try {
    const response = await Axiosapi.get(`${GET_EVENT_TOPIC}`);
    return response;
  } catch (error) {
    console.log("error", error);
  }
}


export const getEventSubTopic = async () => {
  try {
    const response = await Axiosapi.get(`${GET_EVENT_SUB_TOPIC}`);
    return response;
  } catch (error) {
    console.log("error", error);
  }
}

export const getEventPeopleRange = async () => {
  try {
    const response = await Axiosapi.get(`${GET_EVENT_PEOPLE_RANGE}`);
    return response;
  } catch (error) {
    console.log("error", error);
  }
}

export const getEventsCreate = async (info) => {
  try {
    const response = await Axiosapi.post(`${GET_EVENTS_CREATE}`, info, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    console.log("error", error);
  }
}


//Packages Form
export const getPredefinePackage = async () => {
  try {
    const response = await Axiosapi.get(`${GET_PREDEFINE_PACKAGE}`);
    return response;
  } catch (error) {
    console.log("error", error);
  }
}

export const createPackageEvent = async (info) => {
  try {
    const response = await Axiosapi.post(`${CREATE_PACKAGES_EVENT}`, info, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    console.log("error", error);
  }
}

// pitch Form 

export const createPitchform = async (info) => {
  try {
    const response = await Axiosapi.post(`${PITCH_FORM}`, info, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    console.log("error", error);
  }
}

// organizer pitch Form 

export const createOrganizerPitchform = async (info) => {
  try {
    const response = await Axiosapi.post(`${ORGANIZER_PITCH_FORM}`, info, {
      // headers: {
      //   "Content-Type": "text/html; charset=UTF-8",
      // },
    });
    return response;
  } catch (error) {
    console.log("error", error);
  }
}


// my event 

export const getmyevent = async () => {
  try {
    const response = await Axiosapi.get(`${GET_MY_EVENT}`);
    return response;
  }
  catch (error) {
    console.log("error", error)
  }
}

// event detail 

export const eventdatail = async (id) => {
  try {
    const response = await Axiosapi.get(`${EVENT_DETAIL}/${id}`);
    return response;
  }
  catch (error) {
    console.log("error", error)
  }
}


export const getSponsor = async () => {
  try {
    const response = await Axiosapi.get(`${GET_SPONSOR}`);
    return response;
  }
  catch (error) {
    console.log("error", error)
  }
}

// all confing data

export const getConfigData = async () => {
  try {
    const response = await Axiosapi.get(`${GET_CONFIG_DATA}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

export const getProfile = async () => {
  try {
    const response = await Axiosapi.get(`${GET_PROFILE}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}