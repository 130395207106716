import React, { useEffect, useState } from 'react';
import MainBanner from '../../components/Home/MainBanner';
import About from '../../components/Home/About';
import WhyUs from '../../components/Home/WhyUs';
import Speakers from '../../components/Home/Speakers';
import EventSchedules from '../../components/Home/EventSchedules';
import FunFact from '../../components/Common/FunFact';
import Pricing from '../../components/Home/Pricing';
import Partner from '../../components/Common/Partner';
import LatesNews from '../../components/Home/LatestNews';
import BuyTicket from '../../components/Common/BuyTicket';
import Subscribe from '../../components/Common/Subscribe';
import Footer from '../../components/Common/Footer';
import GoTop from '../../components/Shared/GoTop';
import EventNear from '../../components/Home/EventNear';
import Featured from '../../components/Home/Featured';
import UserTestimonials from '../../components/Home/UserTestimonials';
import WhySponsorRingUsComp from '../../components/Home/WhySponsorRingUsComp';
import EventBanner from '../../components/Home/Eventbanner';
import LastMinute from '../../components/Home/LastMinute';
import BottomBanner from '../../components/Home/BottomBanner';
import { Bannerdata } from "../../utils/homeManager/BannerManager";
import SocialFeeds from '../../components/Home/SocialFeeds';
import PastEvents from '../../components/Home/PastEvents';
import { ToastContainer, toast } from 'react-toastify';
import { loginUserData } from '../../utils/user/UserManager';
import { useDispatch, useSelector } from 'react-redux';
import { loginmessage } from '../../redux/Login';
import { getSponsorList } from '../../utils/sponsordata/MySponsorData';



const Home = () => {
    const loginmsg = useSelector((state) => state?.login?.message)
    const dispatch = useDispatch()
    // console.log("msg",loginmsg)
    const [mainBannerList, setMainBannerList] = useState([])
    const [midBannerList, setmidBannerList] = useState([])
    const [bottomBannerList, setbottomBannerList] = useState([])
    const [partnerPostarList, setpartnerPostarList] = useState([])
    const [loading,setLoading]=useState(true);



    const GetBanner = async () => {
        try {
          const result = await Bannerdata();
    
          setMainBannerList(result?.filter((data) => data?.banner_type === "Main Banner"));
          setmidBannerList(result?.filter((data) => data?.banner_type === "Mid Banner"));
          setbottomBannerList(result?.filter((data) => data?.banner_type === "Bottom Banner"));
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false)
        }
      };

    useEffect(() => {
        GetBanner()

        getSponsorList()
            .then((result) => {
                setpartnerPostarList(result?.data)
            })
    }, []);



    useEffect(() => {
        if (loginmsg !== "") {
            toast.success(loginmsg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            dispatch(loginmessage(""))
        }
    }, [loginmsg])



    return (
        <>
            {/* Main Banner */}

            <ToastContainer />

            <MainBanner mainBannerList={mainBannerList} loading={loading}/>

            <EventNear />

            <Featured />

            <EventBanner midBannerList={midBannerList} loading={loading}/>

            <PastEvents />

            <UserTestimonials />

            <SocialFeeds />

            <FunFact />

            <WhySponsorRingUsComp />

            <BottomBanner bottomBannerList={bottomBannerList} loading={loading}/>
            {/* AboutArea */}
            {/* <About /> */}

            {/* Why Choose Us Area */}
            {/* <WhyUs /> */}

            {/* Speakers Area */}
            {/* <Speakers /> */}

            {/* Schedule Area */}
            {/* <EventSchedules /> */}

            {/* FunFacts Area */}
            {/* <FunFact /> */}

            {/* Pricing Area */}
            {/* <Pricing /> */}

            {/* Partner Area */}
            <Partner partnerPostarList={partnerPostarList} />

            {/* Blog Area */}
            {/* <LatesNews /> */}

            {/* Buy Tickets Area */}
            {/* <BuyTicket /> */}

            {/* Subscribe Area */}
            {/* <Subscribe /> */}

            {/* Footer Area */}

            {/* Back Top top */}
            {/* <GoTop scrollStepInPx="50" delayInMs="16.66" /> */}
        </>
    );
};

export default Home;