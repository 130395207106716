

export const BASE_URL = 'https://ringus.idea2reality.tech/api/v1'

export const BANNER_URL = '/banners?banner_type=all';

// login register
export const USER_LOGIN_URL = '/auth/login';
export const USER_REGISTER_URL = '/auth/register';

// basic information

export const GET_EVENT_TYPE = "/events/get_type";
export const GET_EVENT_TOPIC = "/events/get_categories"
export const GET_EVENT_SUB_TOPIC = "/events/get_sub_categories/3";
export const GET_EVENT_PEOPLE_RANGE = "/events/get_people_range";
export const GET_EVENTS_CREATE = "/events/create";

// packages form
export const GET_PREDEFINE_PACKAGE = "/events/get_predefine_package";
export const CREATE_PACKAGES_EVENT = "/packages/create_event_package/1"

// pitch form 
export const PITCH_FORM = "/events/create_event_pitch_data/24"

// organizer pitch form
export const ORGANIZER_PITCH_FORM = "/events/create_event_organizer_data/24"

// my event
export const GET_MY_EVENT = "/events/get_my_event";

// event detail 
export const EVENT_DETAIL = "/events/event_details";

// sponsor user or comapny 
export const GET_SPONSOR = "/get_sponsor";


//config all data

export const GET_CONFIG_DATA = '/config'

export const GET_PROFILE = "/customer/info"
