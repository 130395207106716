import { Circles } from "react-loader-spinner";


const Loader=()=>{
    return(<>
    <div className="loader-main-div">
    <div className="loader-div d-flex align-items-center justify-content-center">
        <Circles
          height="80"
          width="80"
          color="#f43254"
          ariaLabel="circles-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
        </div>
        </div>
    </>)
};

export default Loader;