import EventPlannerimg from "../../assets/images/EventPlanners.svg";
import right from "../../assets/images/right.svg";
import VettedVendors from "../../assets/images/VettedVendors.svg";
import SecuredMarketplace from "../../assets/images/SecuredMarketplace.svg"

const WhySponsorRingUsCard = () => {
  return (
    <>

      <div className="d-flex justify-content-center gap-5 row">
        <div className="col-md-4 EventPlannersCard">
          <img src={EventPlannerimg} alt="img1" />
          <div className="text-center text-light">
            <h5 className="event-planners-head">Event Planners</h5>

            <div className="d-flex gap-2">
              <img className="" src={right} alt="img" />
              <p className="SeamlessPlatformTh">
                Seamless platform that allows you to manage all of your event
                needs.
              </p>
            </div>

            <div className="d-flex gap-2">
              <img src={right} alt="img" />
              <p className="SeamlessPlatformTh">
                Receive competitive quotes & manage the entirety of your event.
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-4 EventPlannersCard">
          <img src={VettedVendors} alt="img1" />
          <div className="text-center text-light">
            <h5 className="event-planners-head"> Vetted Vendors</h5>

            <div className="d-flex gap-2">
              <img className="rights" src={right}  alt="img" />
              <p className="SeamlessPlatformTh">
                 Best in class vendors showcasing innovative products and services for the planners upcoming event.
              </p>
            </div>
            <div className="d-flex gap-2">
              <img  src={right} alt="img" />
              <p className="SeamlessPlatformTh">
              Providing exclusive vendors with 24/7 access to potential new clients.
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-4 EventPlannersCard">
          <img src={SecuredMarketplace} alt="img1" />
          <div className="text-center text-light">
            <h5 className="event-planners-head"> Vetted Vendors</h5>

            <div className="d-flex  gap-2">
              <img  src={right}  alt="img" />
              <p className="SeamlessPlatformTh">
              Ability for event planners to book multiple vendors for any event.
              </p>
            </div>
            
            <div className="d-flex gap-2">
              <img  src={right} alt="img" />
              <p className="SeamlessPlatformTh">
              A way for a planner to consolidate transactions and order status within a single secure platform.
              </p>
            </div>
          </div>
        </div>


      </div>
    </>
  );
};

export default WhySponsorRingUsCard;
