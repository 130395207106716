import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import loca from "../../assets/images/location.png";
import search from "../../assets/images/search.png";
import { FaStar } from "react-icons/fa6";
import chaticon from "../../assets/images/chat-icon.svg";
import notifiicon from "../../assets/images/notification-icon.svg";
import { Token } from "../../utils/Token";
import { UserProfile } from "../../utils/user/UserManager";
import { useSelector } from "react-redux";

const Navigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [homenav, setHomenav] = useState(true);
  const [login, setLogin] = useState(false);
  const [avatarDropdown, setAvatarDropdown] = useState(false);
  const [currencyCode, setCurrecyCode] = useState([]);
  const [userProfile,setUserProfile]=useState({})
  const dropdownRef = useRef(null);


  const allConfingData = useSelector((state) => state?.config_data?.configData)
  // console.log(allConfingData)
  

  // close dropdown on outside click
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setAvatarDropdown(false);
    }
  };

  useEffect(() => {
 

    UserProfile().then((data)=>{
      setUserProfile(data?.data)
    })
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const Tokenn = Token();
    if (Tokenn !== "" && Tokenn !== undefined && Tokenn !== null) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  }, []);

  // useEffect(() => {
  //   function googleTranslateElementInit() {
  //     new window.google.translate.TranslateElement(
  //       { pageLanguage: 'en', autoDisplay: false, },
  //       'google_translate_element'
  //     );
  //   }

  //   // var $googleDiv = $("#google_translate_element .skiptranslate");
  //   // var $googleDivChild = $("#google_translate_element .skiptranslate div");
  //   // $googleDivChild.next().remove();

  //   // $googleDiv.contents().filter(function () {
  //   //   return this.nodeType === 3 && $.trim(this.nodeValue) !== '';
  //   // }).remove();

  //   if (window.google && window.google.translate) {
  //     googleTranslateElementInit();
  //   } else {
  //     const interval = setInterval(() => {
  //       if (window.google && window.google.translate) {
  //         clearInterval(interval);
  //         googleTranslateElementInit();
  //       }
  //     }, 500);
  //   }
  // }, [location.pathname]);

  useEffect(() => {
    const googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        { pageLanguage: "en" },
        "google_translate_element"
      );

      const checkExist = setInterval(() => {
        const googleDiv = document.querySelector(
          "#google_translate_element .skiptranslate"
        );
        const googleDivChild = document.querySelector(
          "#google_translate_element .skiptranslate div"
        );
        if (googleDiv && googleDivChild) {
          googleDivChild.nextElementSibling?.remove();
          Array.from(googleDiv.childNodes).forEach((node) => {
            if (
              node.nodeType === Node.TEXT_NODE &&
              node.textContent.trim() !== ""
            ) {
              node.remove();
            }
          });
          clearInterval(checkExist);
        }
      }, 100);

      setTimeout(() => clearInterval(checkExist), 5000);
    };

    if (window.google && window.google.translate) {
      googleTranslateElementInit();
    } else {
      const script = document.createElement("script");
      script.src =
        "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  const Logout = () => {
    localStorage.removeItem("LoginToken");
    navigate("/login");
  };

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    const handleScroll = () => {
      const elementId = document.getElementById("navbar");
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
        // window.history.pushState("", document.title, window.location.pathname);
      } else {
        elementId.classList.remove("is-sticky");
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (location.pathname == "/") {
      setHomenav(true);
    } else {
      setHomenav(false);
    }
  }, [location.pathname]);

  const toggleOpen = () => setIsOpen(!isOpen);

  //   const onRouteChanged = () => {
  //     setIsOpen(!isOpen);
  //   };

  const classOne = collapsed
    ? "collapse navbar-collapse "
    : "collapse navbar-collapse show bg-dark";
  const classTwo = collapsed
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";
  //   const menuClass = `dropdown-menu${isOpen ? " show" : ""}`;
  return (
    <header id="header" className="header-area">
      <div
        id="navbar"
        className={`${homenav ? "elkevent-home-nav" : "elkevent-nav"} `}
      >
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            {/* logo */}
            <Link className="nav-logo" to="/">
              <img className="logo-img" src={`${allConfingData?.base_urls?.company_logo_url}/${allConfingData?.company_logo}`} alt="logo" />
            </Link>

            {/* ========================================== */}
            {/* mobile screen  */}
            {/* ============================================= */}
            <div className="d-flex d-lg-none ">
              {login ? (
                <div className="">
                  <ul className="n ms-auto">
                    <li className="nav-item dropdown position-relative">
                      <button
                        className="nav-link dropdown-toggle text-white"
                        onClick={() => setAvatarDropdown(!avatarDropdown)}
                      >
                        <img
                          className="rounded-circle avatar-toggle-btn"
                          src="https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp"
                          alt="User Avatar"
                          width="40"
                          height="40"
                        />
                      </button>
                      {avatarDropdown ? (
                        <ul
                          className="d-flex flex-column avatar-dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <li>
                            {" "}
                            <Link to="" className="avatar-dropdown-item">
                              My Profile
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/yourevents"
                              className="avatar-dropdown-item"
                            >
                              My Events
                            </Link>
                          </li>
                          <li>
                            <Link to="" className="avatar-dropdown-item">
                              My Sponsored Events
                            </Link>
                          </li>
                          <li>
                            <Link to="" className="avatar-dropdown-item">
                              Setting
                            </Link>
                          </li>
                          {/* <li><hr className="dropdown-divider"/></li> */}
                          <li>
                            <button
                              className="avatar-dropdown-item"
                              onClick={() => Logout()}
                            >
                              Logout
                            </button>
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                  </ul>
                </div>
              ) : (
                <div className="others-option d-lg-none me-3">
                  <div className="login-signup-div  gap-2">
                    <div>
                      <Link
                        to="/login"
                        className="signin-btn nav-link"
                        onClick={toggleNavbar}
                      >
                        Sign in
                      </Link>
                    </div>

                    <div>
                      <Link
                        to="/signup"
                        className="signup-btn"
                        onClick={toggleNavbar}
                      >
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>
              )}

              {/* togglebutton */}
              <button
                onClick={toggleNavbar}
                className={classTwo}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>

            <div className="input-group search-input-maindiv d-lg-none rounded hidden">
              <form className="">
                <div className="main-input">
                  <input
                    type="text"
                    className=" search-input"
                    placeholder="Search location..."
                    aria-label="Event Search"
                    aria-describedby="searchButton"
                  />
                  <img className="search-img" src={search} alt="img" />

                  <div className="location-input">
                    <p className="m-0 p-0">udaipur </p>
                  </div>

                  <img className="location-img" src={loca} alt="img" />

                  {/* <button className="btn btn-primary" type="button" >Search</button> */}
                </div>
              </form>
            </div>

            {/* ========================================== */}
            {/* mobile screen end*/}
            {/* ============================================= */}

            <div className={`${classOne} pt-4 pt-lg-0`}>
              <div className="input-group search-input-maindiv d-none d-lg-flex rounded hidden">
                <form className="">
                  <div className="main-input">
                    <img className="search-img" src={search} alt="img" />

                    <input
                      type="text"
                      className="search-input"
                      placeholder="Search Location..."
                      aria-label="Event Search"
                      aria-describedby="searchButton"
                    />
                    <div className="location-input">
                      <p className="location-text  text-end m-0 p-0">
                        <img className="location-img mb-1 me-1" src={loca} alt="img" />
                        Udaipur
                      </p>
                    </div>

                    {/* <button className="btn btn-primary" type="button" >Search</button> */}
                  </div>
                </form>
              </div>

              <ul className="ul-list mb-0">
                <li className="nav-item ">
                  <button
                    exact="true"
                    to="#"
                    onClick={toggleOpen}
                    className="nav-btn"
                  >
                    Hows it Work
                  </button>
                </li>

                <li className="nav-item">
                  <button
                    exact="true"
                    to="#"
                    onClick={toggleOpen}
                    className="nav-btn"
                  >
                    Discover Events
                  </button>
                </li>

                <li>
                  <select
                    className="currency-select-button"
                    id="locationSelect"
                    aria-label="language"
                  >
                    {allConfingData.currency_list?.map((val) => {
                      return <option key={val?.id} value=''>{val?.code}</option> 
                    })}
                  </select>
                </li>

                <li className="googleT">
                  <div id="google_translate_element"></div>
                </li>
              </ul>
            </div>

            <div className="others-option other-option-div d-lg-block">
              {login ? (
                <>
                  <div className={`${classOne}`} id="navbarNav">
                    <div className="d-flex pb-2 pb-xl-0">
                      <div className="d-flex gap-3 align-items-start align-items-lg-center ms-4 flex-lg-row flex-column"></div>

                      <div className="d-none d-xl-block" ref={dropdownRef}>
                        <ul className="navbar-nav ms-auto">
                          <li className="nav-item dropdown position-relative">
                            <button
                              className=" nav-link dropdown-toggle text-white"
                              onClick={() => setAvatarDropdown(!avatarDropdown)}
                            >
                              <img
                                className="avatar-toggle-btn rounded-circle"
                                src={`https://ringus.idea2reality.tech/storage/app/public/profile/${userProfile?.image}`}
                                alt="User Avatar"
                                width="40"
                                height="40"
                              />
                            </button>
                            {avatarDropdown ? (
                              <ul
                                className="d-flex flex-column avatar-dropdown-menu"
                                aria-labelledby="navbarDropdown"
                              >
                                <li>
                                  <div className="d-flex align-items-center justify-content-center user_info">
                                    <div className="">
                                      <p className="user_name_text"> {`${userProfile?.f_name} ${userProfile?.l_name} `}</p>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <Link
                                    to=""
                                    className="avatar-dropdown-item"
                                    onClick={() => setAvatarDropdown(false)}
                                  >
                                    My Profile
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/yourevents"
                                    onClick={() => setAvatarDropdown(false)}
                                    className="avatar-dropdown-item"
                                  >
                                    My Events
                                  </Link>
                                </li>
                                <li>
                                  <button className="Upgrade-btn avatar-dropdown-item">
                                    {/* <FaStar className="me-2" size={18} /> */}
                                    Upgrade
                                  </button>
                                  <li>
                                    <button className="avatar-dropdown-item">
                                      Chat
                                      {/* <img className="" src={chaticon} alt="chaticon" /> */}
                                    </button>
                                  </li>
                                  <li>
                                    <button className="avatar-dropdown-item">
                                      Notification
                                      {/* <img className="nav-btn " src={notifiicon} alt="chaticon" /> */}
                                    </button>
                                  </li>
                                </li>
                                <li>
                                  <Link
                                    to=""
                                    className="avatar-dropdown-item"
                                    onClick={() => setAvatarDropdown(false)}
                                  >
                                    My Sponsored Events
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to=""
                                    className="avatar-dropdown-item"
                                    onClick={() => setAvatarDropdown(false)}
                                  >
                                    Setting
                                  </Link>
                                </li>
                                {/* <li><hr className="dropdown-divider"/></li> */}
                                <li>
                                  <button
                                    className="avatar-dropdown-item"
                                    onClick={() => Logout()}
                                  >
                                    Logout
                                  </button>
                                </li>
                              </ul>
                            ) : (
                              ""
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <ul className="login-signup-div d-none d-lg-flex gap-2">
                  <li>
                    <Link
                      to="/login"
                      className="signin-btn nav-link"
                      onClick={toggleNavbar}
                    >
                      Sign in
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/signup"
                      className="signup-btn"
                      onClick={toggleNavbar}
                    >
                      Sign up
                    </Link>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Navigation;
