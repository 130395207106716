import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/SponsorRingUs-icon.svg";
import OtpInput from "react-otp-input";

const ForgotPassword = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState("");
  const [emailError,setEmailError]=useState("")
  // OTP seaction state
  const [otpSection, setOtpSection] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError,setOtpError]=useState("");
 //new pass seaction state
  const [newPasswordSection, setNewPasswordSection] = useState(false);
  const [newPasswordForm,setNewPasswordForm]=useState({
    email:email,
    newPassword:"",
    confirmNewPassword:""
  });
  const [newpPasswordError,setNewpPasswordError]=useState({})


  const onEmailFormSubmit = (e) => {
    e.preventDefault(); 
      if (!email || !email.trim() === '') {
        setEmailError('Email is required')
      }else{
        setEmailError("")
        setOtpSection(true);
      } 
  };

   const onOTPFormSubmit=(e)=>{
    e.preventDefault(); 
    console.log("otp|", otp)

    if (!otp || !/^[0-9]{6}$/.test(otp)) {
      setOtpError('Invalid OTP. Please enter a 6-digit number.');
    } else {
      setOtpError("")
      setNewPasswordSection(true)
      setOtpSection(false)
    }
   }


   const handleNewPassword=(e)=>{
    setNewPasswordForm({...newPasswordForm,[e.target.name]:e.target.value})
   }

   const onNewPasswordFormSubmit=(e)=>{
    e.preventDefault(); 
    const newErrors = {}
    if (!newPasswordForm.email || !newPasswordForm.email.trim() === '') {
      newErrors.email = "Email is required"
    }
    if (!newPasswordForm.newPassword || !newPasswordForm.newPassword.trim() === '') {
      newErrors.newPassword = 'Password is required';
    } else if (newPasswordForm.newPassword.trim().length < 8) {
      newErrors.newPassword = 'Password should be at least 8 characters long';
    } else {
      newErrors.newPassword = '';
    }

    if (!newPasswordForm.confirmNewPassword || !newPasswordForm.confirmNewPassword.trim() === '') {
      newErrors.confirmNewPassword = 'Confirm password is required';
    } else if (newPasswordForm.confirmNewPassword !== newPasswordForm.newPassword) {
      newErrors.confirmNewPassword = 'Passwords do not match';
    } else {
      newErrors.confirmNewPassword = '';
   }
      setNewpPasswordError(newErrors);

      if (newErrors.email || newErrors.newPassword || newErrors.confirmNewPassword) {
        return;
      } else {
        navigate("/login")
      }
  }

  return (
    <>
      <section className="forgotpassword-area">
        <div className="login-signup-header">
          <Link to="/">
            <div className="w-100">
              <img src={logo} alt="logo" />{" "}
            </div>
          </Link>
        </div>

        <div className="d-table1">
          <div className="container-fluid">
            <div className="forgotpassword-form mt-5">
              <div className="d-flex justify-content-between">
                {otpSection ? (
                  <h3>Enter Your OTP</h3>
                  ) : newPasswordSection ? 
                  <h3>Enter New Password</h3>  : (
                  <h3>Forgot Your Password</h3>
                 )}
              </div>

              <div className="d-flex gap-4">
                <div className="">
                {otpSection ? 
                <>
                  <form onSubmit={onOTPFormSubmit}>
                      <div className="form-group mb-1">
                        <label className="forgotpassword-label">OTP</label>
                        <OtpInput
                          inputStyle={"form-control w-100 mb-2"}
                          value={otp}
                          onChange={setOtp}
                          numInputs={6}
                          renderSeparator={<span>-</span>}
                          renderInput={(props) => <input {...props} />}
                        />
                        
                      {otpError && <span className="" style={{ color: 'red', fontSize: '13px' }}>{otpError}</span>}
                      </div>
                      <div className="d-flex flex-column">
                      <button type="submit" className="btn forgotpassword-btn mt-2">
                        Submit
                      </button>
                      <Link to="/login" className="forgot-text mt-4">
                        Back to login
                      </Link>
                    </div>
                  </form> 
                  </>
                    :
                    newPasswordSection ?      
                    <form onSubmit={onNewPasswordFormSubmit}>
                      <div>
                      <div className="form-group mb-2">
                        <label className="forgotpassword-label">Email</label>
                        <input
                          type="email"
                          className="form-control  rounded-3"
                          placeholder="Enter Email"
                          name="email"
                          value={newPasswordForm?.email}
                          onChange={handleNewPassword}
                        />
                      </div>
                      {newpPasswordError.email && <span className="" style={{ color: 'red', fontSize: '13px' }}>{newpPasswordError.email}</span>}
                    </div>

                    <div>
                      <div className="form-group mb-2">
                        <label className="forgotpassword-label">New Password</label>
                        <input
                          type="text"
                          className="form-control  rounded-3"
                          placeholder="Enter new password"
                          name="newPassword"
                          value={newPasswordForm?.newPassword}
                          onChange={handleNewPassword}
                        />
                      </div>
                      {newpPasswordError.newPassword && <span className="" style={{ color: 'red', fontSize: '13px' }}>{newpPasswordError.newPassword}</span>}
                    </div>

                    <div>
                      <div className="form-group mb-2">
                        <label className="forgotpassword-label">Confirm New Password</label>
                        <input
                          type="text"
                          className="form-control  rounded-3"
                          placeholder="Enter confirm new password"
                          name="confirmNewPassword"
                          value={newPasswordForm?.confirmNewPassword}
                           onChange={handleNewPassword}
                        />
                      </div>
                      {newpPasswordError.confirmNewPassword && <span className="" style={{ color: 'red', fontSize: '13px' }}>{newpPasswordError.confirmNewPassword}</span>}
                    </div>

                    <div className="d-flex flex-column">
                      <button type="submit" className="btn forgotpassword-btn mt-2">
                        Submit
                      </button>
                      <Link to="/login" className="forgot-text mt-4">
                        Back to login
                      </Link>
                    </div>
                  </form>
                   
                    :
                  
      
                  <form onSubmit={onEmailFormSubmit}>
                      <>
                      <div className="form-group mb-2">
                        <label className="forgotpassword-label">Email</label>
                        <input
                          type="email"
                          className="form-control  rounded-3"
                          placeholder="Enter Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      {emailError && <span className="" style={{ color: 'red', fontSize: '13px' }}>{emailError}</span>}
                    </>
                    <div className="d-flex flex-column">
                      <button type="submit" className="btn forgotpassword-btn mt-2">
                        Submit
                      </button>
                      <Link to="/login" className="forgot-text mt-4">
                        Back to login
                      </Link>
                    </div>
                  </form>
                     }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
