import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import facebook from "../../assets/images/facebook-icon.png";
import linkedin from "../../assets/images/linkedin-icon.png";
import google from "../../assets/images/google-icon.png";
import logo from "../../assets/images/SponsorRingUs-icon.svg";
import instagram from "../../assets/images/icons8-instagram-48.png";
import { loginUserData } from "../../utils/user/UserManager";
import { useAuth0 } from "@auth0/auth0-react";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { loginmessage } from "../../redux/Login";

const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  //updated code
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      email: email,
      password: password,
    };

    const newErrors = {};
    if (!email || !email.trim() === "") {
      newErrors.email = "Email is required";
      setLoading(false);
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email is invalid";
      setLoading(false);
    } else {
      newErrors.email = "";
    }

    if (!password || !password.trim() === "") {
      newErrors.password = "Password is required";
      setLoading(false);
    } else if (password.trim().length < 8) {
      newErrors.password = "Password should be at least 8 characters long";
      setLoading(false);
    } else {
      newErrors.password = "";
    }
    setErrors(newErrors);

    if (newErrors.email || newErrors.password) {
      return;
    } else {
      try {
        const response = await loginUserData(data);
        const token = response?.data?.token;
        const message = response?.data?.message;

        dispatch(loginmessage(message));

        if (token) {
          localStorage.setItem("LoginToken", token);
          navigate("/");
        }
      } catch (error) {
        toast.error(error?.response?.data?.errors[0]?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
    setEmail("");
    setPassword("");
    setLoading(false);
  };

  return (
    <>
      <section className="login-area">
        <ToastContainer />
        <div className="login-signup-header">
          <Link to="/">
            <div className="w-100">
              {" "}
              <img src={logo} alt="logo" />{" "}
            </div>
          </Link>
        </div>

        <div className="d-table1">
          <div className="container-fluid">
            <div className="login-form mt-5">
              <div className="d-flex justify-content-between">
                <h3>Sign In</h3>
                <p className="Dont-have-an-accoun">
                  Don’t have an account?{" "}
                  <Link to={"/signup"}>
                    {" "}
                    <span className="text-style-1"> Sign Up</span>
                  </Link>
                </p>
              </div>

              <div className="d-flex gap-5 mt-4">
                <div className="">
                  <p className="login-p">Sign In with Social Account</p>
                  <div className="d-flex flex-column gap-4 mt-4">
                    <button
                      className="login-with-facebook"
                      onClick={loginWithRedirect}
                    >
                      <img
                        className="login-btn-icon"
                        src={facebook}
                        alt="linkedin"
                      />
                      Continue with Facebook
                    </button>

                    <button
                      className="login-with-linkedin"
                      onClick={loginWithRedirect}
                    >
                      <img
                        className="login-btn-icon"
                        src={linkedin}
                        alt="linkedin"
                      />
                      Continue with Linkedin
                    </button>
                    <button
                      className="login-with-google"
                      onClick={loginWithRedirect}
                    >
                      <img
                        className="login-btn-icon"
                        src={google}
                        alt="linkedin"
                      />
                      Continue with Google
                    </button>

                    <button className="login-with-instagram">
                      <img
                        className="login-btn-icon"
                        src={instagram}
                        alt="instagram"
                      />
                      Continue with Instagram
                    </button>
                  </div>
                </div>

                <div className="">
                  <div className="center-line ms-3"></div>
                  <div className="or-div">
                    <span className="or-text">OR</span>
                  </div>
                  <div className="center-line ms-3"></div>
                </div>

                <div className="">
                  <p className="login-p">Sign In with email</p>
                  <form onSubmit={onSubmit}>
                    <div className="form-group">
                      <label className="login-label">Email</label>
                      <input
                        type="email"
                        className="form-control  rounded-3"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />

                      {errors.email && (
                        <span
                          className=""
                          style={{ color: "red", fontSize: "13px" }}
                        >
                          {errors.email}
                        </span>
                      )}
                    </div>

                    <div className="form-group">
                      <label className="login-label">Password</label>
                      <input
                        type="password"
                        className="form-control rounded-3"
                        placeholder="Enter password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      {errors.password && (
                        <span
                          className="error-msg"
                          style={{ color: "red", fontSize: "13px" }}
                        >
                          {errors.password}
                        </span>
                      )}
                    </div>
                    <div className="d-flex flex-column">
                      <Link to="/forgotpassword" className="forgot-text">
                        Forgot password?
                      </Link>
                      <button type="submit" className="btn d-flex align-items-center justify-content-center gap-1 login-btn mt-4">
                        {loading ? (
                          <div
                            className="spinner-border  text-light"
                            role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        ) : (
                          ""
                        )}
                        Sign In
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
